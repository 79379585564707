export type Frame = { thumbnail: string; time: number };

export const getCloudinaryFrames = (url: string, intervals: number[]): Promise<Frame[]> => {
  const [first, second] = url.split('/upload/');
  // add a check see if second is defined
  return Promise.all(
    intervals.map(async (item) => {
      const transformed = `${first}/upload/so_${item}/${second}`;
      await fetch(transformed);
      return {
        thumbnail: getImageJPG(transformed),
        time: item,
      };
    }),
  );
};

export const getImageJPG = (url: string) => {
  let l = url.split('.');
  l.pop();
  let b = l.join('.');
  return b + '.jpg';
};

export const getVideoPoster = (url: string) => {
  let l = url.split('.');
  l.pop();
  let b = l.join('.');
  return b + '.jpg';
};
export type Bubble_Talk_Size = 'md' | 'sm';

export const createTextOverlayUrl = (text: string, x: number, y: number, url: string) => {
  if (!text) {
    return url;
  }
  let cropType = 'lfill';
  if (text.length > 30) {
    cropType = 'fit';
  }
  // const textWidth = 230;
  let X = x < 0 ? 0 : x;
  let Y = y < 0 ? 0 : y;
  const gravity = X === 0 && Y === 0 ? 'g_center' : 'g_north';

  const replacedStr = replaceSymbols(text);
  const textString = `co_rgb:fff,c_${cropType},l_text:open%20sans_16_solid_line_height_24_font_size_16:${replacedStr},r_5,w_380,h_200,x_${X},y_${Y},${gravity}`;
  const [first, second] = url.split('/v1/');
  if (first && second) {
    return `${first}/${textString}/v1/${second}`;
  }
  return url;
};

export function replaceSymbols(text: string) {
  const symbols = [
    { key: `%`, code: '%2525' },
    { key: `?`, code: '%253F' },
    { key: `:`, code: '%253A' },
    { key: `,`, code: '%252C' },
    { key: `;`, code: '%253B' },
    { key: `"`, code: '%2522' },
    { key: `$`, code: '%2524' },
    { key: `&`, code: '%2526' },
    { key: `#`, code: '%2523' },
    { key: `@`, code: '%2540' },
    { key: `+`, code: '%252B' },
    { key: `=`, code: '%253D' },
    { key: `^`, code: '%255E' },
    { key: `[`, code: '%255B' },
    { key: `]`, code: '%255D' },
    { key: `{`, code: '%257B' },
    { key: `}`, code: '%257D' },
  ];
  let output = text;
  for (let i = 0; i < symbols.length; i++) {
    if (output.includes(symbols[i].key)) {
      output = output.replace(symbols[i].key, symbols[i].code);
    }
  }
  return output;
}

export const getThumbnailWithBubbleTalk = (link: string, size: Bubble_Talk_Size) => {
  let output = '';
  const url = googleStorageToCloudinary(link);
  const [first, second] = url.split('/v1/');
  if (url.includes('topgolf-shots')) {
    output = `${first}/${TALK_BUBBLE_OVERLAY_DEV_URL(size)}/v1/${second}`;
  }
  if (url.includes('sv-prod-assets')) {
    output = `${first}/${TALK_BUBBLE_OVERLAY_PROD_URL(size)}/v1/${second}`;
  }
  if (url.includes('sv-dev-stories')) {
    output = `${first}/${TALK_BUBBLE_OVERLAY_DEV_URL(size)}/v1/${second}`;
  }
  if (url.includes('sv-prod-stories')) {
    output = `${first}/${TALK_BUBBLE_OVERLAY_PROD_URL(size)}/v1/${second}`;
  }
  return output;
};

export const googleStorageToCloudinary = (url: string) => {
  let l = url.split('?')[0] || '';

  if (l.includes('https://storage.googleapis.com/')) {
    l = l.replace('https://storage.googleapis.com/', 'https://res.cloudinary.com/socialvenu/image/upload/v1/');
  }
  if (l.includes('http://storage.googleapis.com/')) {
    l = l.replace('http://storage.googleapis.com/', 'https://res.cloudinary.com/socialvenu/image/upload/v1/');
  }
  return l;
};

const TALK_BUBBLE_OVERLAY_DEV_URL = (size: Bubble_Talk_Size) => {
  return `c_fit,g_north,l_sv-dev-assets:talk_bubble_overlay_${size},y_20,w_380`;
};
const TALK_BUBBLE_OVERLAY_PROD_URL = (size: Bubble_Talk_Size) => {
  return `c_fit,g_north,l_sv-prod-assets:talk_bubble_overlay_${size},y_20,w_380`;
};

export const removeTextOverlay = (url: string) => {
  if (url) {
    const cropType = url.includes('lfill') ? 'lfill' : 'fit';
    const gravity = url.includes('g_center') ? 'g_center' : 'g_north';
    const regexp = new RegExp(`,c_${cropType}(.*?)${gravity}`);
    const arrUrl = url.split(regexp);
    return arrUrl[2] ? arrUrl[0].concat(arrUrl[2]) : url;
  }
  return url;
};

export const handleCopyFallback = async (url: string, videoUrl?: string) => {
  const thumbnailWithPlayIcon = getThumbnailWithPlayIconV2(url);
  // Detect all style sheets of the page
  var activeSheets = Array.prototype.slice.call(document.styleSheets).filter(function(sheet) {
    return !sheet.disabled;
  });

  if (videoUrl) {
    console.log('fallback used with vide Url');
    const img = document.createElement('img');
    img.src = thumbnailWithPlayIcon;
    img.style.minHeight = '300px';
    img.style.minWidth = '140px';
    img.style.objectFit = 'contain';
    const a1 = document.createElement('a');
    a1.href = videoUrl;
    const a2 = document.createElement('a');
    a2.href = videoUrl;
    a2.innerText = 'Watch Now';
    a1.appendChild(img);
    const div2 = document.createElement('br');
    const div = document.createElement('div');
    div.style.minHeight = '400px';
    div.appendChild(a1);
    div.appendChild(div2);
    div.appendChild(a2);
    document.body.appendChild(div);

    window?.getSelection()?.removeAllRanges();

    const myrange = document.createRange();
    myrange.setStartBefore(div);
    myrange.setEndAfter(div);
    myrange.selectNode(div);

    var sel = window.getSelection();
    sel?.addRange(myrange);
    document.execCommand('copy');
    for (var i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = true;
    const status = document.execCommand('copy');
    for (var i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = false;
    document.body.removeChild(div);
    if (status) {
      return true;
    } else {
      throw new Error('no available');
    }
  } else {
    console.log('fallback used');
    const img = document.createElement('img');
    img.src = url;
    document.body.appendChild(img);

    const myrange = document.createRange();
    myrange.setStartBefore(img);
    myrange.setEndAfter(img);
    myrange.selectNode(img);

    var sel = window.getSelection();
    sel?.addRange(myrange);
    const status = document.execCommand('copy');
    document.body.removeChild(img);
    if (status) {
      return true;
    } else {
      throw new Error('no available');
    }
  }
};

export const getThumbnailWithPlayIconV2 = (link: string) => {
  let output = '';
  let url = link;

  if (!url.includes('/v1/')) {
    url = googleStorageToCloudinary(link);
  }

  const [first, second] = url.split('/v1/');
  if (url.includes('sv-dev-assets')) {
    output = `${first}/${PLAY_ICON_DEV_URL}/v1/${second}`;
  }
  if (url.includes('sv-prod-assets')) {
    output = `${first}/${PLAY_ICON_PROD_URL}/v1/${second}`;
  }
  if (url.includes('sv-dev-stories')) {
    output = `${first}/${PLAY_ICON_DEV_URL}/v1/${second}`;
  }
  if (url.includes('sv-prod-stories')) {
    output = `${first}/${PLAY_ICON_PROD_URL}/v1/${second}`;
  }
  return output;
};

const PLAY_ICON_DEV_URL = 'c_fit,g_center,l_sv-dev-assets:play_icon_eosxsm,w_80';
const PLAY_ICON_PROD_URL = 'c_fit,g_center,l_sv-dev-assets:play_icon_xi3zxw,w_80';

export async function copyHtml(imageUrl: string, url: string, isChrome: boolean, introText?: string) {
  if (window) {
    const text = getHtml(url, imageUrl, introText);
    const type = 'text/html';
    const hasClipboardItem = typeof ClipboardItem !== 'undefined';
    if (!hasClipboardItem) {
      console.log('hasClipboardItem', hasClipboardItem);
    }
    if (hasClipboardItem) {
      console.log('ischrome', isChrome);
      if (isChrome) {
        const blob = new Blob([text], { type });
        // @ts-ignore
        const data = [new ClipboardItem({ [type]: blob })];
        try {
          await navigator.clipboard.write(data);
        } catch (err) {
          console.log('clipboard error', err);
        }
      } else {
        const getAsyncBlob = async (text: string) => {
          const blob = new Blob([text], { type });
          return blob;
        };
        const blob = await getAsyncBlob(text);
        const textType = 'text/plain';
        const blob2 = new Blob(['   '], { type: textType });
        // @ts-ignore
        const data = [new ClipboardItem({ [type]: blob }), new ClipboardItem({ [textType]: blob2 })];
        navigator.clipboard.write(data);
      }
    } else {
      handleCopyFallback(imageUrl, url);
    }
  }
}

const getHtml = (url: string, imageUrl: string, introText?: string) => {
  const img = getThumbnailWithPlayIcon(imageUrl);
  return `
  <div>
    ${
      introText
        ? `<p style= '
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    display: flex;'>${introText}</p>`
        : `<p></p>`
    }
    <a href='${url}' style='box-sizing:border-box; font-size:10px;'>
    <img
          title='video'
          src='${img}'
          alt='Thumbnail for video'
          style='box-sizing: border-box; padding: 0px; margin: 0px; width: auto; height: 276px;' />
    </a>
    <a  href='${url}'
        style='box-sizing: border-box;  padding: 0px; 
        font-size: 14px; font-weight: 400; text-decoration: none'>
    <p  style='height:30px; padding: 10px 42px; 
        width:140px; background: #17B790; border-radius: 8px; text-decoration: none; color:white; text-align:center; padding-top:10px'>
    Open Video
    </p>
    </a>
    </div>
    `;
};

export const getThumbnailWithPlayIcon = (link: string) => {
  let output = '';
  const url = googleStorageToCloudinary(link);
  const [first, second] = url.split('/v1/');
  if (url.includes('sv-dev-assets')) {
    output = `${first}/${PLAY_ICON_DEV_URL}/v1/${second}`;
  }
  if (url.includes('sv-prod-assets')) {
    output = `${first}/${PLAY_ICON_PROD_URL}/v1/${second}`;
  }
  if (url.includes('sv-dev-stories')) {
    output = `${first}/${PLAY_ICON_DEV_URL}/v1/${second}`;
  }
  if (url.includes('sv-prod-stories')) {
    output = `${first}/${PLAY_ICON_PROD_URL}/v1/${second}`;
  }
  return output;
};

export async function copyImage(url: string, isChromeAndNotiOS: boolean) {
  if (window) {
    const imageURL = googleStorageToCloudinary(url);
    console.log('imageURL', imageURL);
    if (isChromeAndNotiOS) {
      const bloburl = imageURL.replace('.jpg', '.png');
      const blob = await imageToBlob(bloburl);
      await navigator.clipboard.write([
        // @ts-ignore
        new ClipboardItem({ 'image/png': blob }),
      ]);
    } else {
      const blob = imageToBlob(imageURL);
      const item = new ClipboardItem({ 'image/png': blob });
      navigator.clipboard.write([item]);
    }
  }
}

export function imageToBlob(imageURL: string): Promise<Blob> {
  const img = new Image();
  const c = document.createElement('canvas');
  const ctx = c.getContext('2d');
  img.crossOrigin = '';
  img.src = imageURL;
  return new Promise((resolve) => {
    img.onload = function() {
      c.width = img.naturalWidth;
      c.height = img.naturalHeight;
      ctx && ctx.drawImage(img, 0, 0);
      c.toBlob(
        (blob) => {
          // here the image is a blob
          resolve(blob as Blob);
        },
        'image/jpg',
        0.75,
      );
    };
  });
}

export const getVideoWithoutText = (url: string) => {
  if (!url) return '';
  const [first, second] = url.split('co_rgb:fff');
  if (!second) return url;
  let [, ...rest] = second.split('/');
  return `${first}${rest.join('/')}`;
};

export const googleStorageVideoUrlToCloudinaryUrl = (url: string) => {
  let l = url.split('?')[0] || '';

  if (l.includes('https://storage.googleapis.com/')) {
    l = l.replace('https://storage.googleapis.com/', 'https://res.cloudinary.com/socialvenu/video/upload/v1/');
  }
  if (l.includes('http://storage.googleapis.com/')) {
    l = l.replace('http://storage.googleapis.com/', 'https://res.cloudinary.com/socialvenu/video/upload/v1/');
  }
  return l;
};
