import { StoriesApiModel, StoriesWithDateTime } from '../api/models/stories';
import { compareTime, convertTZ } from './time';

export function isUnique<Item>(item: Item, index: number, array: Item[]) {
  return array.indexOf(item) === index;
}

export function findLastIndex<Item>(array: Item[], predicate: (item: Item) => boolean) {
  let result = -1;

  for (let i = array.length - 1; i >= 0; --i) {
    if (predicate(array[i])) {
      result = i;
      break;
    }
  }

  return result;
}

export const extendStoriesWithTimeSlots = (items: StoriesApiModel[], increment: number): StoriesWithDateTime[] => {
  return items
    .map((item) => {
      const date = convertTZ(item.shotTime, 'PST');
      const [hours, minutes] = [date.getHours(), date.getMinutes()];
      const totalminutes = hours * 60 + minutes;
      const hoursReminder = totalminutes % 60;
      const Increminder = hoursReminder % increment;
      const slotHour = (totalminutes - hoursReminder) / 60;
      const slotMinutes = hoursReminder - Increminder !== 0 ? hoursReminder - Increminder : '00';
      const timeSlot = `${slotHour}:${slotMinutes}`;

      return {
        ...item,
        hours,
        minutes,
        timeSlot,
      };
    })
    .sort(compareTime);
};
