import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '10px',
  },
  title: {
    position: 'relative',
    zIndex: 1,
    marginBottom: 20,
    color: '#fff',
    fontSize: 38,
    fontWeight: 700,
    lineHeight: 1.2,
    textShadow: '0 4px 20px rgba(0, 0, 0, 0.5)',

    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      marginBottom: 16,
      fontSize: 32,
    },
  },
  subtitle: {
    position: 'relative',
    zIndex: 1,
    color: '#fff',
    fontSize: 20,
    fontWeight: 400,
    lineHeight: 1.62,
    textShadow: '0 4px 20px rgba(0, 0, 0, 0.5)',

    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      fontSize: 16,
    },
  },
}));
