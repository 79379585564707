import { GallerySlashIcon } from '../../../assets/GallerySlashIcon';
import { NoContent } from '../../localization/en';

import { NoContentStyles } from './NoContent.styles';
import { NoContentSlider } from './NoContentSlider';

const CustomNoContent = () => {
  const styles = NoContentStyles();
  return (
    <>
      <div className={styles.thumbnailsContainer}>
        <NoContentSlider initialIndex={2} />
        <div className={styles.thumbnailsCaptionContainer}></div>
      </div>
    </>
  );
};

export default CustomNoContent;
