import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { useAppDispatch, useTypedSelector } from '../../../store/store';
import { setIsMenuOpen } from '../../../store/slices/ui';
import { makeStyles } from '@mui/styles';
import { BottomBar } from './BottomBar';
import { CloseIcon } from '../../assets/CloseIcon';

const useStyles = makeStyles((theme) => ({
  container: {
    background: 'black',
    color: 'white',
  },
  drawer: {
    width: '75%',
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    backgroundColor: 'black',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '60vw',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 612,
      paddingLeft: 120,
    },
  },
  logo: {
    minWidth: 'auto !important',
    width: 'auto !important',
    margin: ' 0 !important',
    height: ' 35px',
  },
  topBar: {
    display: 'flex',
    width: '100%',
    maxHeight: '58px',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '-13px auto -10px',
    marginBottom: '24px',
    position: 'relative',
    zIndex: 2,
    [theme.breakpoints.up('sm')]: {
      width: '50vw',
    },
    [theme.breakpoints.up('lg')]: {
      width: '400px',
    },
  },
}));

export const MenuOptions = [
  {
    title: 'Email Us',
    onClick: () => {
      const a = document.createElement('a');
      a.href = 'mailto:feedback@socialvenu.com';
      a.click();
    },
  },
  {
    title: 'Live Chat',
    onClick: () => {
      if (window && window.$crisp) {
        window.$crisp.push(['do', 'chat:open']);
      }
    },
  },
  {
    title: 'Terms of Service',
    onClick: () => {
      const a = document.createElement('a');
      a.href = 'https://socialvenu.com/terms';
      a.target = '_blank';
      a.click();
    },
  },
];

export const Menu = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const isOpen = useTypedSelector((state) => state.ui.isMenuOpen);
  const { logo } = useTypedSelector((state) => state.venue.venue);
  const setIsOpen = (value: boolean) => {
    dispatch(setIsMenuOpen(value));
  };

  const list = () => (
    <Box sx={{ width: 250 }} role="presentation" className={classes.container}>
      <List>
        <div className={classes.topBar}>
          <img className={classes.logo} src={logo} alt="topgolf logo" />
          <span onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </span>
        </div>
        {MenuOptions.map((option) => (
          <ListItem
            button
            key={option.title}
            onClick={() => {
              setIsOpen(false);
              option.onClick();
            }}
          >
            {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
            <ListItemText primary={option.title} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <Drawer
      classes={{ paper: classes.drawer }}
      anchor="left"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      elevation={0}
    >
      {list()}
      <BottomBar />
    </Drawer>
  );
};
