import { makeStyles } from '@mui/styles';

export const noContentStyles = makeStyles(() => ({
  slide: {
    width: '89px',
    height: '131px',
    borderRadius: '7px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    // background: '#1D1C1C',
    transform: 'scale(1.60) translate(-35%, -29%)',
    transition: 'transform 300ms',
  },
  img: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    objectFit: 'cover',
    borderRadius: '7px',
    // opacity: 0.3,
  },
  activeSlide: {
    width: '89px',
    height: '131px',
    borderRadius: '7px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    // background: '#414141',
    transition: 'transform 300ms',
    transform: 'scale(1.92) translate(-27%, -27%)',
    zIndex: 100,
  },
  imgActiveSlide: {
    opacity: 1,
  },
  distance1Slide: {
    width: '88px',
    height: '131px',
    borderRadius: '7px',
    position: 'absolute',
    opacity: 1,
    top: '50%',
    left: '50%',
    background: '#222222',
    transition: 'transform 300ms',
    transform: 'scale(1.25) translate(-40%, -33%)',
    zIndex: 50,
  },
  imgDistance1Slide: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    borderRadius: '7px',
    objectFit: 'cover',
    opacity: 0.6,
  },
  NoContentImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },

  imgactiveSlide: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    borderRadius: '7px',
    objectFit: 'contain',
    opacity: 1,
  },
  filler: {
    height: '251px',
    width: '1px',
  },
  shotTimeContainer: {
    marginTop: '15px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  shotTime: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    fontSize: '10px',
    lineHeight: '12px',
    color: ' #B4B4B4',
  },
  shotTimeText: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '12px',
    /* identical to box height */
    textAlign: 'center',
    color: '#5E5E5E',
    whiteSpace: 'nowrap',
    marginRight: '3px',
  },
}));
