import { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { DateElement } from './TopBarElements/TopBarDatePicker';
import { Grid, Theme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from '../../constants';
import { SmallSquareButton, SmallSquareButtonType } from '../SmallSquareButton/SmallSquareButton';
import { useTypedSelector } from '../../../store/store';
import { getAlteredTheme } from '../../../services/hooks/themeHook';

const useStyles = makeStyles((theme: Theme) => ({
  topBarBlock: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'sticky',
    height: '60px',
    zIndex: 30,
    top: 0,
    background: '#000',
  },
  logo: {
    width: 40,
    height: 40,
    paddingRight: '10px',
    marginRight: '5px',
    objectFit: 'contain',
  },
  menuButtonStyles: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: '#fff',
    gap: 5,
    padding: 0,
    fontSize: 10,
  },
  dateElement: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  Title: {
    fontWeight: 700,
    fontSize: '22px',
    lineHeight: '28px',
    color: 'white',
    textAlign: 'center',
    width: '100%',
  },
  subtitle: {
    fontSize: '14px',
    color: 'white',
    textAlign: 'center',
    opacity: 0.5,
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  menuIcon: {
    width: '50px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const TopBar: FC = () => {
  const styles = useStyles();
  const location = useLocation();
  const {
    venueAttributes,
    venue: { logo },
  } = useTypedSelector((state) => state.venue);

  const openCrisp = () => {
    if (window && window.$crisp) {
      window.$crisp.push(['do', 'chat:open']);
    }
  };

  const isDatePicker = location.pathname === AppRoutes.home;
  const alteredTheme = getAlteredTheme(venueAttributes);

  return (
    <>
      <Grid container className={styles.topBarBlock} justifyContent="space-between" alignItems="center" spacing={0}>
        <Grid item xs={1}>
          <div className={styles.menuIcon}>
            <SmallSquareButton
              onButtonClick={openCrisp}
              buttonType={SmallSquareButtonType.Hamburger}
              color={alteredTheme.palette.primary.main}
              transparent
            />
          </div>
        </Grid>
        {isDatePicker ? (
          <Grid item xs={9} className={styles.dateElement}>
            <DateElement />
          </Grid>
        ) : (
          <Grid item xs={9}>
            <p className={styles.Title}>
              {' '}
              Share <span className={styles.primaryColor}> Video </span>{' '}
            </p>
          </Grid>
        )}
        <Grid item xs={1}>
          <img src={logo} alt="Top Golf" className={styles.logo} />
        </Grid>
      </Grid>
    </>
  );
};
