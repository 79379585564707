import { FC, useState } from 'react';
import { isChrome, isSafari, isIOS, browserVersion } from 'react-device-detect';

//material ui imports
import { makeStyles } from '@mui/styles';
import { Theme, Collapse, Grid, ButtonBase, Box, IconButton } from '@mui/material';

import styles from '../../Share.module.css';
// import { downloadingIcons } from '../UnifiedPreview/UnifiedPreview.helper';
// icon import

import {
  InstagramIcon,
  DownloadIcon,
  TikTokIcon,
  RedditIcon,
  ReelsIcon,
  ShareIcontIos,
  ArrowDown,
} from '../../../../common/assets/shareIcons/index';

import { PredefinedMessages, Toast } from '../../../../common/components/Toast/Toast';

import {
  ChromeIosDownloadHelperStepsStrings,
  SafariIosDownloadHelperStepsStrings,
} from '../InstagramPreview/ShareInstagram';
import { SharePageStrings } from '../../../../common/localization/en';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
  },
  text: {
    color: theme.palette.secondary.contrastText,
  },
  iconButton: {
    width: '44px',
    height: '44px',
    borderRadius: '50%',
    border: '1px solid #fff',
    color: '#fff',
    margin: '10px',
    '&:hover': {
      backgroundColor: '#fff',
      stroke: '#000',
      fill: '#fff',
      color: '#000',
    },
  },
  collapseText: {
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '28px',

    color: '#F4F2EF',
  },
  activeIcon: {
    backgroundColor: '#fff',

    '&>svg': {
      stroke: '#000',
      fill: '#fff',
      color: '#000',
    },
  },
}));

type ShareInstagramProps = {
  copyHandler: () => void;
  onDownloadClick: (type: string) => void;
  copyInstagramLink: boolean;
};

export const DownloadPreview: FC<ShareInstagramProps> = ({ onDownloadClick }) => {
  const classes = useStyles();
  const [showToolTip, setShowToolTip] = useState(false);
  const [isDownloadClicked, setIsDownloadClicked] = useState(false);
  const [isToast, setIsToast] = useState(false);
  const downloadHandler = (type: string) => {
    setIsDownloadClicked(true);
    onDownloadClick(type);
    if (isIOS && isSafari) {
      setIsToast(true);
    }
  };
  const downloadingIcons = [
    { icon: <InstagramIcon />, name: 'instagram', handler: () => downloadHandler('Instagram'), isActive: false },
    { icon: <TikTokIcon />, name: 'TikTok', handler: () => downloadHandler('TIKTOK'), isActive: false },
    { icon: <ReelsIcon />, name: 'Reels', handler: () => downloadHandler('REELS'), isActive: false },
    { icon: <RedditIcon />, name: 'Reddit', handler: () => downloadHandler('REDDIT'), isActive: false },
    { icon: <DownloadIcon />, name: 'Download', handler: () => downloadHandler('DOWNLOAD'), isActive: false },
  ];
  const [downloadingOptions, setDownloadingOptions] = useState(downloadingIcons);

  const message =
    isSafari && Number(browserVersion) >= 15
      ? PredefinedMessages.downloadSnackBarSafari15
      : PredefinedMessages.downloadSnackBackr;

  const handleToggleToolTip = () => {
    setShowToolTip(!showToolTip);
    setIsDownloadClicked(true);
  };
  const handleSelectOptionsToDownload = (handler: () => void, name: string) => {
    handler ? handler() : {};
    const arr = downloadingOptions.slice().map((item) => {
      if (item.name === name) {
        return { ...item, isActive: !item.isActive };
      } else {
        return { ...item, isActive: false };
      }
    });
    setDownloadingOptions([...arr]);
  };
  const renderIosHelperTexts = () => {
    if (isSafari) {
      return (
        <>
          <p className={classes.collapseText}>{SafariIosDownloadHelperStepsStrings.step1} </p>
          <p className={classes.collapseText}>{SafariIosDownloadHelperStepsStrings.step2} </p>
          <p className={classes.collapseText}>
            {SafariIosDownloadHelperStepsStrings.step3}
            <ArrowDown />
            {SafariIosDownloadHelperStepsStrings.icon}
          </p>
          <p className={classes.collapseText}>{SafariIosDownloadHelperStepsStrings.step4} </p>
          <p className={classes.collapseText}>
            {SafariIosDownloadHelperStepsStrings.step5}
            <ShareIcontIos />
            {SafariIosDownloadHelperStepsStrings.shareOtions}{' '}
          </p>
          <p className={classes.collapseText}>{SafariIosDownloadHelperStepsStrings.step6} </p>
        </>
      );
    } else if (isChrome) {
      return (
        <>
          <p className={classes.collapseText}>{ChromeIosDownloadHelperStepsStrings.step1} </p>
          <p className={classes.collapseText}>
            {ChromeIosDownloadHelperStepsStrings.step2}
            <span style={{ color: 'white' }}>{ChromeIosDownloadHelperStepsStrings.openIn}</span>{' '}
            {ChromeIosDownloadHelperStepsStrings.downloadTab}{' '}
          </p>
          <p className={classes.collapseText}>
            {ChromeIosDownloadHelperStepsStrings.step3}
            <span style={{ color: 'white' }}>{ChromeIosDownloadHelperStepsStrings.openInDownloads}</span>{' '}
            {ChromeIosDownloadHelperStepsStrings.option}
          </p>
          <p className={classes.collapseText}>{ChromeIosDownloadHelperStepsStrings.step4} </p>
          <p className={classes.collapseText}>
            {ChromeIosDownloadHelperStepsStrings.step5}
            <ShareIcontIos />
            {ChromeIosDownloadHelperStepsStrings.shareOtions}{' '}
          </p>
          <p className={classes.collapseText}>{ChromeIosDownloadHelperStepsStrings.step6} </p>
        </>
      );
    }
  };

  return (
    <Grid container flexDirection="column" className={[styles.svNav, styles.share].join(' ')}>
      <Toast
        message={message}
        open={isToast}
        setOpen={setIsToast}
        position={
          message === PredefinedMessages.downloadSnackBarSafari15
            ? { vertical: 'bottom', horizontal: 'left' }
            : { vertical: 'top', horizontal: 'right' }
        }
      />
      <Box sx={{ justifyContent: { sx: 'flex-start', sm: 'center' } }} className={styles.svNavTitle}>
        <span>{SharePageStrings.WhereDownloading}</span>
      </Box>
      <Grid container item justifyContent="center">
        {downloadingOptions.map((item) => (
          <IconButton
            onClick={() => handleSelectOptionsToDownload(item.handler, item.name)}
            className={[classes.iconButton, item.isActive ? classes.activeIcon : ''].join(' ')}
            key={item.name}
          >
            {item.icon}
          </IconButton>
        ))}
      </Grid>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <ButtonBase
          className={classes.collapseText}
          sx={{ margin: '0 auto', textDecoration: 'underline' }}
          onClick={handleToggleToolTip}
        >
          {isIOS && SharePageStrings.HowToDownload}
        </ButtonBase>
        <Collapse in={showToolTip} timeout="auto" unmountOnExit>
          {isDownloadClicked && isIOS && <>{renderIosHelperTexts()}</>}
        </Collapse>
      </Box>
    </Grid>
  );
};
