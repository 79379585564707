import { FC, useRef } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType, EffectCards } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-cards';

import { noContentStyles } from './NoContentSlider.style';

interface ThumbnailSliderProps {
  initialIndex: number;
}

export const NoContentSlider: FC<ThumbnailSliderProps> = ({ initialIndex }) => {
  const styles = noContentStyles();
  const swiperInstance = useRef<SwiperType | null>(null);

  const thumbnails = [0, 1, 2, 3, 4];

  const slidesJsx = thumbnails.map((index) => {
    let slideClassName = styles.slide;
    let imageClassName = styles.img;

    const distanceFromCenter = Math.abs(index - initialIndex);

    if (distanceFromCenter === 0) {
      slideClassName = styles.activeSlide;
      imageClassName = styles.imgactiveSlide;
    } else if (distanceFromCenter === 1) {
      // slideClassName = styles.distance1Slide;
      // imageClassName = styles.imgDistance1Slide;
    }
    if (distanceFromCenter === 0) {
      return (
        <SwiperSlide key={thumbnails[index]}>
          <div className={slideClassName} key={index}>
            <img src="/no-videos-card.png" alt="no video content" className={imageClassName} />
          </div>
          <div className={styles.filler} />
        </SwiperSlide>
      );
    }
    return (
      <SwiperSlide key={thumbnails[index]}>
        <div className={slideClassName} key={index}>
          <img src="/no-videos-card-no-text.png" alt="no video content" className={imageClassName} />
        </div>
        <div className={styles.filler} />
      </SwiperSlide>
    );
  });

  return (
    <Swiper
      style={{ overflow: 'hidden' }}
      allowSlideNext={false}
      allowSlidePrev={false}
      initialSlide={initialIndex}
      effect={'cards'}
      grabCursor={true}
      modules={[EffectCards]}
      // spaceBetween={80}
      // slidesPerView={5}
      // centeredSlides={true}
      slideToClickedSlide={true}
      onSwiper={(swiper) => {
        swiperInstance.current = swiper;
      }}
    >
      {slidesJsx}
    </Swiper>
  );
};
