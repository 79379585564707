import { FC } from 'react';

interface ArrowDownIconProps {
  fill?: string;
}
export const ArrowDownIcon: FC<ArrowDownIconProps> = ({ fill = 'white' }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line
        x1="3.75"
        y1="20.25"
        x2="18.25"
        y2="20.25"
        stroke={fill || 'white'}
        stroke-opacity="0.3"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <line
        x1="3.75"
        y1="20.25"
        x2="7.25"
        y2="20.25"
        stroke={fill || 'white'}
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M11 16.5C15.1421 16.5 18.5 13.1421 18.5 9C18.5 4.85786 15.1421 1.5 11 1.5C6.85786 1.5 3.5 4.85786 3.5 9C3.5 13.1421 6.85786 16.5 11 16.5Z"
        stroke={fill || 'white'}
        stroke-width="1.1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 6.375V10.875"
        stroke={fill || 'white'}
        stroke-width="1.1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.75 9.375L11 11.625L13.25 9.375"
        stroke={fill || 'white'}
        stroke-width="1.1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
