import { GetServerSideProps } from 'next';
import { ReactElement } from 'react';
import { Header } from '../common/header';
import { getApiUrlForId, VenueEndpoints } from '../src/api/endpoints';
import { VenueAPIModel } from '../src/api/models/venue';
import { Main } from '../src/pages/Main/Main';
import { httpClient } from '../src/services/httpClient/httpClient';
import { getSubdomain } from '../src/services/utilities';

interface MainPageProps {
  venueLogo: string;
  metaOGTitle: string;
  metaOGDescription: string;
  metaTwitterImg: string;
  metaTwitterTitle: string;
  metaTwitterDescription: string;
  metaTwitterSiteId: string;
  metaOGImg: string;
  venueId: string;
}

export const getServerSideProps: GetServerSideProps = async (context): Promise<{ props: MainPageProps }> => {
  const host = getSubdomain(context.req.headers.host);
  try {
    const venueData = await httpClient.get<undefined, VenueAPIModel>({
      url: getApiUrlForId(VenueEndpoints.Venue, host),
      requiresToken: false,
    });
    return {
      props: {
        metaOGTitle: venueData.name,
        metaOGDescription: '',
        metaOGImg: venueData.name,
        metaTwitterImg: venueData.logo,
        metaTwitterTitle: venueData.name,
        metaTwitterDescription: '',
        metaTwitterSiteId: '',
        venueLogo: venueData.logo,
        venueId: venueData.id,
      },
    };
  } catch (e) {
    console.log('error index.tsx', e);
    return {
      props: {
        metaOGTitle: 'Top Golf',
        metaOGDescription: '',
        metaOGImg: '',
        metaTwitterImg: '',
        metaTwitterTitle: '',
        metaTwitterDescription: '',
        metaTwitterSiteId: '',
        venueLogo: '',
        venueId: '',
      },
    };
  }
};

const Home = (props: MainPageProps) => {
  return (
    <>
      <Header
        metaOGTitle={props.metaOGTitle}
        metaOGDescription={props.metaOGDescription}
        metaOGImg={props.metaOGImg}
        metaOGVideo=""
        metaTwitterTitle={props.metaTwitterTitle}
        metaTwitterDescription={props.metaTwitterDescription}
        metaTwitterImg={props.metaTwitterImg}
        metaTwitterSiteId={props.metaTwitterSiteId}
        title={props.metaOGTitle}
        venueLogo={props.venueLogo}
        venueId={props.venueId}
      />
      <Main />
    </>
  );
};
Home.getLayout = function getLayout(page: ReactElement) {
  return <div>{page}</div>;
};
export default Home;
