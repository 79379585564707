import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      display: 'none',
    },
  },
  slider: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-24px',
      marginRight: '-35px',
    },
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    overflowX: 'scroll',
    zIndex: 20,
  },
  slide: {
    maxWidth: '85px',
    minWidth: '85px',
    width: '85px',
    flex: '0 0 auto',
    marginRight: '4px',
    borderRadius: '45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#0b4c8c',
    ':first-child': {
      marginLeft: '10px',
    },
    ':last-child': {
      marginRight: '10px',
    },
    zIndex: 20,
  },
  activeSlide: {
    backgroundColor: '#f4b93b',
    zIndex: 20,
    border: '1px solid #FFEDC7',
  },
  time: {
    margin: '0 auto',
    padding: '5px 0 4px',
    color: 'white',
    fontSize: '12px',
  },
  activeTime: {
    margin: '0 auto',
    padding: '5px 0 4px',
    color: 'black',
    fontSize: '12px',
  },
}));
