import { FC } from 'react';

export const ArrowDown: FC = () => (
  <svg width={18} height={21} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line
      x1={1.75}
      y1={20.25}
      x2={16.25}
      y2={20.25}
      stroke="white"
      strokeOpacity={0.3}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <line x1={1.75} y1={20.25} x2={5.25} y2={20.25} stroke="white" strokeWidth={1.5} strokeLinecap="round" />
    <path
      d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
      stroke="white"
      strokeWidth={1.1}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9 6.375V10.875" stroke="white" strokeWidth={1.1} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M6.75 9.375L9 11.625L11.25 9.375"
      stroke="white"
      strokeWidth={1.1}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
