import { FC } from 'react';
export const ShareIcontIos: FC = () => (
  <svg width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.9405 8.89993C20.5005 8.90039 21.9705 8.59039 22.0105 16.8999V15.2399C22.0105 21.4999 20.2205 21.4999 15.7505 21.4999H9.24047C4.77047 21.4999 2.98047 21.9003 2.98047 17.4303V15.1099C2.98047 8.89993 4.50049 8.90993 7.97047 8.90993"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.5 15.0011V3.62109" stroke="white" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M15.8484 5.85L12.4984 2.5L9.14844 5.85"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
