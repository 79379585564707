import * as React from 'react';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { Alert, Slide, SlideProps } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { ArrowDownIcon } from '../../../assets/ArrowDownIcon';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

export enum PredefinedMessages {
  downloadSnackBackr = 'Download Snack Bar',
  downloadSnackBarSafari15 = 'Download Snack Bar safari 15',
}

interface PositionedSnackbarProps {
  message: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  position?: SnackbarOrigin;
}

export const Toast: React.FC<PositionedSnackbarProps> = ({
  message,
  setOpen,
  open,
  position = {
    vertical: 'top',
    horizontal: 'right',
  },
}) => {
  const { vertical, horizontal } = position;

  const handleClose = () => {
    setOpen(false);
  };
  let content;
  switch (message) {
    case PredefinedMessages.downloadSnackBackr:
      content = (
        <Alert icon={false} color="warning" style={{ backgroundColor: '#F4B93B' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <span style={{ fontSize: '12px' }}>Tap this</span> <ArrowDownIcon fill="#5B3F02" />{' '}
            <span style={{ fontSize: '12px' }}>icon above to see downloaded video</span> <ArrowUpwardIcon />
          </div>
        </Alert>
      );
      break;
    case PredefinedMessages.downloadSnackBarSafari15:
      content = (
        <Alert icon={false} color="warning" style={{ backgroundColor: '#F4B93B' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <ArrowDownwardIcon /> <span style={{ fontSize: '12px' }}>Tap this</span> <ArrowDownIcon fill="#5B3F02" />{' '}
            <span style={{ fontSize: '12px' }}>icon bellow to see downloaded video</span>
          </div>
        </Alert>
      );
      break;
    default:
      content = (
        <Alert onClose={handleClose} severity="success">
          {message}
        </Alert>
      );
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={6000}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
        TransitionComponent={SlideTransition}
      >
        {content}
      </Snackbar>
    </div>
  );
};
