import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { StoryDetails } from '../../../../api/models/auth';
import { SharePageStrings } from '../../../../common/localization/en';

// Icons import

import { LinkedinIcon } from './LinkedinIcon';
import LinkIcon from '@mui/icons-material/Link';

// import InstagramIcon from '@mui/icons-material/Instagram';

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import {
  facebookShareLink,
  linkedinShareLink,
  Messages,
  SocialNetworks,
  twitterShareLink,
  ButtonsTypes,
  EventActions,
} from '../../../../common/constants';
import { useTypedSelector } from '../../../../store/store';

// Previews import
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { ShareSms } from '../SmsPreview/ShareSms';
import { ShareEmail } from '../EmailPreview/ShareEmail';

import { ShareLink } from '../DefaultPreview/ShareLink';
import { copyToClipboard } from '../../../../services/utilities';
import { AppRoutes, getApiUrlWithIdQueryParam } from '../../../../common/constants';
import {
  Bubble_Talk_Size,
  createTextOverlayUrl,
  getThumbnailWithBubbleTalk,
  googleStorageToCloudinary,
} from '../../../../utils';
import { StoryEndpoints } from '../../../../api/endpoints';
import { trackSharesCall } from '../../Share.helper';
import { StoriesApiModel } from '../../../../api/models/stories';
import { trackEvent } from '../../../../utils/analytics/analytics';
import { DownloadPreview } from '../DownloadedPreview/DownloadPreview';
import { DownloadIcon, SmsIcon, TwitterIcon } from '../../../../common/assets/shareIcons';
import { FacebookIcon } from '../../../../common/assets/shareIcons/FacebookIcon';

const icons = [
  {
    name: 'Message' as ButtonsTypes,
    component: <SmsIcon />,
  },
  {
    name: 'Link' as ButtonsTypes,
    component: <LinkIcon />,
  },
  {
    name: 'Facebook' as ButtonsTypes,
    component: <FacebookIcon />,
  },
  {
    name: 'Twitter' as ButtonsTypes,
    component: <TwitterIcon />,
  },
  {
    name: 'LinkedIn' as ButtonsTypes,
    component: <LinkedinIcon />,
  },
  // {
  //   name: 'Email' as ButtonsTypes,
  //   component: <EmailIcon />,
  // },
  {
    name: 'Download' as ButtonsTypes,
    component: <DownloadIcon />,
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  PlayIconWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 4,
    color: theme.palette.common.white,
    cursor: 'pointer',
    zIndex: 16,
  },
  Video: {
    display: 'block',
    objectFit: 'contain',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: 10,
    border: 'none',
    borderRadius: 'inherit',
  },
  VideoWrapper: {
    position: 'relative',
    borderRadius: '16px',
    border: 'none',
    overflow: 'hidden',
    width: '100%',
    paddingTop: 'calc((27 / 32) * 100%)',
    backgroundColor: 'black',
  },
  ComponentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    width: '100%',
    position: 'relative',
    padding: '12px',
    background: 'rgba(69, 69, 69, 0.8)',
    borderRadius: '16px',
    minWidth: '100px',
  },
  image: {
    borderRadius: '16px 16px 8px 8px',
    width: '270px',
    height: '330px',
  },
  cardContainer: {
    background: '#F0F0F0',
    borderRadius: '16px 16px 16px 16px',
    height: '515px',
    width: '333px',
    paddingBottom: '15px',
  },
  buttonShare: {
    backgroundColor: '#9AD9CB',
    color: 'white',
    height: '35px',
    width: '280px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#9AD9CB',
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: '#9AD9CB',
      color: 'white',
      boxShadow: 'none',
    },
  },
  shareNextButton: {
    marginTop: '16px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
    height: '54px',
    fontSize: '18px',
    '&:disabled': {
      backgroundColor: '#272727',
      color: 'white',
    },
  },
  introText: {
    height: '20px',
    color: 'black',
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '16px',
    marginBottom: '5px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  iconWrapper: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '8px',
    width: '40px',
    height: '40px',
    position: 'absolute',
    left: '296px',
    zIndex: '3',
    marginTop: '8px',
    padding: '0px 8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.8)',
  },
  editIcon: {
    backgroundColor: theme.palette.primary.main,
    fill: theme.palette.secondary.contrastText,
    cursor: 'pointer',
    borderRadius: '8px',
    marginTop: '8px',
  },
  videoTextWrapper: {
    position: 'absolute',
    width: '313px',
    height: '55px',
    left: '20',
    marginTop: '16px',
    zIndex: '2',
    background: 'rgba(0, 0, 0, 0.7)',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
  },
  videoText: {
    minHeight: '40px',
    maxHeight: '55px',
    color: theme.palette.common.white,
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '12px',
    alignSelf: 'center',
    overflow: 'auto',
  },
  videoTextWrapperbubble: {
    position: 'absolute',
    width: '0',
    height: '0',
    left: '90px',
    marginTop: '71px',
    transform: 'rotate(-180deg)',
    zIndex: '2',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '15px solid rgba(0, 0, 0, 0.7)',
  },
  shareText: {
    color: '#848484',
    fontWeight: 500,
    fontSize: '14px',
    textAlign: 'center',
    margin: '13px 0 8px 0',
  },
  iconContainer: {
    marginLeft: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '44px',
    height: '44px',
    background: '#595959',
    borderRadius: '8px',
    cursor: 'pointer',
    fill: '#BCBCCC',
  },
  selectedIconContainer: {
    background: theme.palette.primary.main,
    // background: 'rgba(79, 150, 255, 1)',
  },
  PreviewWrapper: {
    marginTop: '20px',
    background: '#272727',
    borderRadius: '16px',
    padding: '16px',
  },
}));

interface PreviewProps {
  posterUrl: string;
  storyDetails?: StoryDetails;
}

type PreviewsType = {
  [key in ButtonsTypes]: ReactJSXElement;
};
type SocialNetwork = 'Twitter' | 'Facebook' | 'Linkedin';

export const UnifiedPreview: FC<PreviewProps> = ({ posterUrl, storyDetails }) => {
  const { shareStory } = useTypedSelector((state) => state.story);
  const {
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const webAppSharing = venueProperties?.['webapp.sharing'];
  const defaultStoryDetails = venueProperties?.['webapp.default-story-details'];
  const { sharingSettings, sharingOrder } = webAppSharing || {};
  const shareStoryId = shareStory?.id;
  const { venue } = useTypedSelector((state) => state.venue);
  const [selectedButton, setSelectedButton] = useState<ButtonsTypes | ''>('');
  const [copyInstagramLink, setCopyInstagramLink] = useState<boolean>(false);
  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);
  const [isShareVisible, setIsShareVisible] = useState<boolean>(true);
  const refVideo = useRef<HTMLVideoElement | null>(null);
  const [isPlay, setIsPlay] = useState(false);

  const handleTap = () => {
    const videoElement = refVideo.current as HTMLVideoElement;
    trackEvent({
      action: videoElement.paused ? EventActions.videoPlay : EventActions.videoPause,
      venueId: venue.id,
      videoId: shareStory?.id,
    });
    setIsPlay(videoElement.paused);
    videoElement[videoElement.paused ? 'play' : 'pause']();
  };
  const onVideoEnd = () => {
    setIsPlay(false);
  };

  const bubbleSize =
    defaultStoryDetails && defaultStoryDetails?.videoText?.length < 48 ? 'sm' : ('md' as Bubble_Talk_Size);

  const thumbnailWithBubbleTalk = getThumbnailWithBubbleTalk(posterUrl, bubbleSize);

  const thumbnailOverlayed = defaultStoryDetails?.videoText
    ? createTextOverlayUrl(
        defaultStoryDetails?.videoText || '',
        0,
        35,
        googleStorageToCloudinary(thumbnailWithBubbleTalk),
      )
    : posterUrl;

  // 52 characters ====== Lorem Ipsum is simply dummy text of the printing and ====== 1 line ======= arials
  // 104 characters
  // printing and Lorem Ipsum is simply dummy text ==== 1 line ===== poppins

  const storyLink =
    window.location.origin +
    getApiUrlWithIdQueryParam(AppRoutes.StoryLink, shareStory ? shareStory.shortcode : shareStoryId);
  useEffect(() => {
    if (window && selectedButton) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [selectedButton]);

  const onClickHandler = (type: ButtonsTypes) => {
    trackEvent({ action: EventActions[type], venueId: venue.id, videoId: shareStory?.id });
    setSelectedButton(type);
  };
  const classes = useStyles();

  useEffect(() => {
    if (copyInstagramLink) {
      setTimeout(() => {
        setCopyInstagramLink(false);
      }, 6000);
    }
  }, [copyInstagramLink]);

  useEffect(() => {
    if (isLinkCopied) {
      setTimeout(() => {
        setIsLinkCopied(false);
      }, 6000);
    }
  }, [isLinkCopied]);

  /*  const mappedOrderedSharingList =
    orderList && orderList.slice().sort((a, b) => (Object.entries(a)[0][1] > Object.entries(b)[0][1] ? 1 : -1)); */

  const onShareButtonClickHandler = () => {
    trackEvent({ action: EventActions.shareClick, venueId: venue.id, videoId: shareStory?.id });
    setIsShareVisible(false);
  };

  const copyLink = (pageUrl: string, socialType?: SocialNetworks) => {
    if (window && shareStoryId?.length) {
      copyToClipboard(
        window.location.origin.concat(
          getApiUrlWithIdQueryParam(pageUrl, shareStory ? shareStory.shortcode : shareStoryId),
        ),
      ).then(() => {
        if (socialType === SocialNetworks.Instagram) {
          setCopyInstagramLink(true);
        } else {
          setIsLinkCopied(true);
        }
      });
      trackSharesCall(shareStoryId, StoryEndpoints.TrackShareClicks, 'LINK');
      trackEvent({
        action: EventActions.copyLink,
        venueId: venue.id,
        videoId: shareStory?.id,
      });
    }
  };
  const handleCta = (type: string) => {
    if (window && shareStoryId?.length) {
      const storyLink = window.location.origin.concat(
        getApiUrlWithIdQueryParam(AppRoutes.StoryLink, shareStory ? shareStory.shortcode : shareStoryId),
      );
      const a = document.createElement('a');
      let textMessage =
        type === Messages.Sms
          ? ` ${storyLink} %0D%0A%0D%0A  ${defaultStoryDetails?.metaTitle}  %0D%0A %0D%0A ${
              defaultStoryDetails?.videoMessage ? defaultStoryDetails?.videoMessage + '%0D%0A%0D%0A' : ''
            } Reply with Y to view Video or STOP %0D%0A %0D%0A `
          : '';
      a.href = type === Messages.Sms ? `sms:?&body=${textMessage}` : `mailto:?body=${textMessage}`;
      trackSharesCall(shareStoryId, StoryEndpoints.TrackShareClicks, type.toUpperCase());
      a.click();
    }
  };

  const handleSMSInContactsCTA = (storyId?: string) => {
    if (window && shareStoryId?.length) {
      const storyLink =
        window.location.origin +
        getApiUrlWithIdQueryParam(
          AppRoutes.StoryLink,
          shareStory && !storyId ? shareStory.shortcode : storyId || shareStoryId,
        );
      const a = document.createElement('a');
      a.href = `sms:?&body= ${storyLink} %0D%0A ${
        defaultStoryDetails?.videoMessage || defaultStoryDetails?.metaTitle || ''
      } `;
      trackSharesCall(shareStoryId, StoryEndpoints.TrackShareClicks, Messages.Sms.toUpperCase()).then(() => {
        // dispatch(getUserRequest(userId));
      });
      a.click();
    }
  };

  const onDownloadClick = (type: string) => {
    const a = document.createElement('a');
    a.download = `topgolfvideo.mp4`;
    const url = shareStory?.url || '';
    const [first, second] = url.split('/upload/');
    a.href = second ? `${first}/upload/fl_attachment/${second}` : url;
    if (shareStoryId?.length) {
      trackSharesCall(shareStoryId, StoryEndpoints.TrackShareClicks, type);
    }
    console.log('a tag', a);
    a.click();
  };

  const socialShareHandler = (type: SocialNetwork) => {
    const links = {
      Twitter: twitterShareLink,
      Facebook: facebookShareLink,
      Linkedin: linkedinShareLink,
    };
    const shareUrl = links[type];
    const storyLink = window.location.origin.concat(
      getApiUrlWithIdQueryParam(AppRoutes.StoryLink, shareStory ? shareStory.shortcode : shareStoryId),
    );
    const encodedUrl = encodeURIComponent(storyLink);
    const a = document.createElement('a');
    a.href = `${shareUrl}${encodedUrl}`;
    a.target = '_blank';
    if (shareStoryId?.length) {
      trackSharesCall(shareStoryId, StoryEndpoints.TrackShareClicks, type.toUpperCase());
    }
    a.click();
  };

  const Previews: PreviewsType = {
    Message: (
      <ShareSms
        shareStory={shareStory as StoriesApiModel}
        posterUrl={thumbnailOverlayed}
        handleCta={() => handleCta(Messages.Sms)}
        handleSMSInContactsCTA={(storyId?: string) => handleSMSInContactsCTA(storyId)}
      />
    ),
    Email: (
      <ShareEmail
        storyLink={storyLink || ''}
        posterUrl={thumbnailOverlayed}
        handleCta={() => handleCta(Messages.Email)}
        introText={defaultStoryDetails?.videoMessage || defaultStoryDetails?.metaTitle || ''}
      />
    ),
    Facebook: (
      <ShareLink
        buttonText="Post Now"
        methodName="Facebook"
        buttonHandler={() => socialShareHandler(SocialNetworks.Facebook)}
      />
    ),
    Twitter: (
      <ShareLink
        buttonText="Post Now"
        methodName="Twitter"
        buttonHandler={() => socialShareHandler(SocialNetworks.Twitter)}
      />
    ),

    LinkedIn: (
      <ShareLink
        buttonText="Post Now"
        methodName="LinkedIn"
        buttonHandler={() => socialShareHandler(SocialNetworks.Linkedin)}
      />
    ),
    Link: (
      <ShareLink
        buttonText="Copy Link to Clipboard"
        methodName="Link"
        isLinkCopied={isLinkCopied}
        buttonHandler={() => copyLink(AppRoutes.StoryLink)}
      />
    ),

    /*   Download: (
      <ShareInstagram
        onDownloadClick={onDownloadClick}
        copyInstagramLink={copyInstagramLink}
        copyHandler={() => copyLink(AppRoutes.StoryLink, SocialNetworks.Instagram)}
      />
    ), */
    Download: (
      <DownloadPreview
        onDownloadClick={onDownloadClick}
        copyInstagramLink={copyInstagramLink}
        copyHandler={() => copyLink(AppRoutes.StoryLink, SocialNetworks.Instagram)}
      />
    ),
  };

  return (
    <div className={classes.ComponentWrapper}>
      <>
        <div className={classes.container}>
          <>
            {shareStory && (
              <div className={classes.VideoWrapper} onClick={handleTap}>
                <video
                  className={classes.Video}
                  src={shareStory.url}
                  playsInline
                  ref={refVideo}
                  onEnded={onVideoEnd}
                  poster={shareStory.posterUrl}
                  muted
                >
                  <source src={shareStory.url} type="video/mp4" />
                </video>
                {!isPlay && (
                  <div className={classes.PlayIconWrapper}>
                    <PlayCircleOutlineIcon sx={{ fontSize: 54 }} />
                  </div>
                )}
              </div>
            )}
            {isShareVisible ? (
              <Button
                className={classes.shareNextButton}
                onClick={onShareButtonClickHandler}
                variant="contained"
                fullWidth
              >
                {SharePageStrings.Share}
              </Button>
            ) : (
              <>
                <p className={classes.shareText}>{SharePageStrings.ShareWith}</p>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  {icons.map((item) => {
                    return (
                      <div
                        key={item.name}
                        className={[
                          classes.iconContainer,
                          item.name === selectedButton ? classes.selectedIconContainer : '',
                        ].join(' ')}
                        onClick={() => onClickHandler(item.name)}
                      >
                        {item.component}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </>
          {selectedButton && (
            <Grid container sx={{ paddingTop: '15px' }}>
              {' '}
              {Previews[selectedButton]}
            </Grid>
          )}
        </div>
      </>
    </div>
  );
};
