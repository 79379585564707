export const CrispChatIcon = () => {
  return (
    <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.0654 17.384L25.0718 17.4237L25.512 17.4874C26.3569 17.6097 27.1417 17.0292 27.2649 16.1909L27.9836 11.3001C28.1068 10.4618 27.5218 9.68319 26.6769 9.56096L25.6986 9.41941C24.5429 4.03423 19.7956 0 14.1148 0C8.43284 0 3.68472 4.03588 2.53028 9.42265L1.37695 9.54863C0.528263 9.6413 -0.0840001 10.3991 0.00941526 11.2411L0.554483 16.154C0.647898 16.996 1.41164 17.6035 2.26033 17.5108L3.37039 17.3896L3.37024 17.384L4.86321 17.1026C4.02673 15.5795 3.5506 13.8278 3.5506 11.9643C3.5506 6.0908 8.28034 1.32937 14.1148 1.32937C19.9492 1.32937 24.6789 6.0908 24.6789 11.9643C24.6789 13.8135 24.2101 15.5524 23.3856 17.0673L25.0654 17.384ZM22.9268 11.6064C22.9268 13.211 22.4912 14.7147 21.7308 16.0073C19.4736 16.7993 16.3822 17.0814 15.8843 17.1227C15.7494 16.8534 15.4692 16.6682 15.1454 16.6682H13.0841C12.6287 16.6682 12.2596 17.0345 12.2596 17.4863V17.5374C12.2596 17.9892 12.6287 18.3555 13.0841 18.3555H15.1454C15.5324 18.3555 15.8571 18.091 15.9459 17.7343C16.5066 17.6988 18.7878 17.5241 21.1167 16.9156C19.5063 19.0031 16.969 20.3495 14.1148 20.3495C13.8034 20.3495 13.4958 20.3335 13.1927 20.3022L9.71755 22L9.52217 19.0697C6.99147 17.5334 5.30271 14.7656 5.30271 11.6064C5.30271 6.7777 9.248 2.86325 14.1148 2.86325C18.9815 2.86325 22.9268 6.7777 22.9268 11.6064ZM10.8167 12.5778C11.329 12.5778 11.7443 12.1658 11.7443 11.6575C11.7443 11.1492 11.329 10.7372 10.8167 10.7372C10.3044 10.7372 9.8891 11.1492 9.8891 11.6575C9.8891 12.1658 10.3044 12.5778 10.8167 12.5778ZM14.1148 12.5778C14.6271 12.5778 15.0424 12.1658 15.0424 11.6575C15.0424 11.1492 14.6271 10.7372 14.1148 10.7372C13.6025 10.7372 13.1872 11.1492 13.1872 11.6575C13.1872 12.1658 13.6025 12.5778 14.1148 12.5778ZM18.3404 11.6575C18.3404 12.1658 17.9251 12.5778 17.4129 12.5778C16.9006 12.5778 16.4853 12.1658 16.4853 11.6575C16.4853 11.1492 16.9006 10.7372 17.4129 10.7372C17.9251 10.7372 18.3404 11.1492 18.3404 11.6575Z"
        fill="#F4F4FC"
      />
    </svg>
  );
};
