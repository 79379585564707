import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const UIInitialState = {
  isMenuOpen: false,
};

const UISlice = createSlice({
  name: 'ui',
  initialState: UIInitialState,
  reducers: {
    setIsMenuOpen(state, action: PayloadAction<boolean>) {
      state.isMenuOpen = action.payload;
    },
  },
});

export const { setIsMenuOpen } = UISlice.actions;
export default UISlice.reducer;
