import { FC, useEffect, useState } from 'react';
import styles from '../../Share.module.css';
// import { QuestionIcon } from '../../../../common/assets/QuestionIcon';
import { CheckMarkIcon } from '../../../../common/assets/CheckMarkIcon';

import { copyImage, handleCopyFallback } from '../../../../utils';
import { isChrome, isIOS } from 'react-device-detect';
import { SharePageStrings } from '../../../../common/localization/en';
import { Done } from '@mui/icons-material';
import { useTypedSelector } from '../../../../store/store';

// import { createDuplicateVideo, updateShareStoryById, getStoryByIdRequest } from '../../../../store/slices/stories';
import { StoriesApiModel } from '../../../../api/models/stories';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

enum ContactOptions {
  MY_CONTACT = 'My Contact',
  NEW_CONTACT = 'New Contact',
}

enum ShareSteps {
  STEP_1,
  STEP_2,
}
const getMaterialStyles = makeStyles((theme: Theme) => ({
  shareSmsBtn: { height: 48, margin: 0 },
  shareListItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    height: 48,
    marginBottom: 10,
    '&:before': {
      counterIncrement: 'my-sec-counter',
      content: 'counter(my-sec-counter)',
      fontSize: 16,
      lineHeight: 40,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      color: theme.palette.primary.main,
    },
  },
  shareListBlock: {
    margin: '15px 0',
    counterReset: 'my-sec-counter',
    gap: 10,
  },
  shareListItemDisabled: {
    opacity: 0.33,
  },
  yellowPoint: {
    color: '#FFB01B',
  },
  bluePoint: {
    color: theme.palette.primary.main,
  },
  shareSMSBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 6,
    color: 'rgba(255, 255, 255, 0.5)',
  },
  shareSMSTitle: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
    marginBottom: 8,
  },
  shareText: {
    display: 'flex',
    flex: 1,
    borderRadius: 8,
    border: '2px solid rgba(142, 142, 155, 1)',
    color: 'rgba(255, 255, 255, 0.8)',
    padding: '0 15px',
    height: '100%',
    width: 160,
  },
  button: {
    '&:disabled': { opacity: 0.33, cursor: 'not-allowed' },
  },
  linearProgress: {
    // boxShadow: '0px 0px 8px rgba(255, 157, 24, 0.72)',
  },
  shareButton: {
    backgroundColor: theme.palette.primary.main,
    // backgroundColor: 'rgba(79, 150, 255, 1)',
    color: theme.palette.secondary.contrastText,
    borderRadius: '50px',
    paddingTop: '12px',
    paddingBottom: '12px',
    height: 'auto',
  },
  shareButtonText: {
    color: theme.palette.secondary.contrastText,
  },
  beforeCopy: {
    backgroundColor: '#17B790',
  },
}));

const contactOptionsList = [
  { name: ContactOptions.MY_CONTACT, img: '/img/myContact.png', imgStyle: styles.imgStyleOne },
  { name: ContactOptions.NEW_CONTACT, img: '/img/newContact.png', imgStyle: styles.imgStyleTwo },
];
const shareScreenTexts = [
  '1. Copy the Video Image',
  '2. Open pre-filled Text Message',
  '3. Paste the Video Image above the Text Message',
];

type ShareSmsProps = {
  posterUrl: string;
  handleCta: () => void;
  handleSMSInContactsCTA: (storyId?: string) => void;
  shareStory: StoriesApiModel;
};

export const ShareSms: FC<ShareSmsProps> = ({ posterUrl, handleCta, handleSMSInContactsCTA }) => {
  const material = getMaterialStyles();
  const [shareStep, setShareStep] = useState<ShareSteps>(ShareSteps.STEP_1);
  const [contactOption, setContactOption] = useState<ContactOptions>(ContactOptions.NEW_CONTACT);
  const [isThumbnailCopied, setIsThumbnailCopied] = useState<boolean>(false);
  const [hasBeenCopied, setHasBeenCopied] = useState<boolean>(false);
  const {
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const sharingSMSNotInContacts = venueProperties?.['webapp.sharing']?.sharingSMSNotInContacts || false;

  useEffect(() => {
    if (window && shareStep === ShareSteps.STEP_2) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [shareStep]);

  useEffect(() => {
    if (isThumbnailCopied) {
      setTimeout(() => {
        setIsThumbnailCopied(false);
      }, 6000);
    }
  }, [isThumbnailCopied]);

  const nextHandler = () => {
    if (contactOption === ContactOptions.MY_CONTACT) {
      handleSMSInContactsCTA();
    } else {
      setShareStep(ShareSteps.STEP_2);
    }
  };

  const handleImageCopy = () => {
    copyImage(posterUrl, isChrome && !isIOS)
      .then(() => {
        setIsThumbnailCopied(true);
        setHasBeenCopied(true);
      })
      .catch((err) => {
        handleCopyFallback(posterUrl)
          .then(() => {
            setIsThumbnailCopied(true);
            setHasBeenCopied(true);
            console.log(err);
          })
          .catch((err) => {
            console.log(err);
          });
      });
  };

  const renderSelectContact = () => (
    <section className={[styles.svNav, styles.share].join(' ')}>
      <div className={styles.svNavTitle}>
        <span>Share Text Message</span>
        {/* <i className={[styles.icon, styles.questionIcon].join(' ')}>
          <QuestionIcon />
        </i> */}
      </div>
      <div className={styles.svNavSubTitle}>
        {contactOption === ContactOptions.MY_CONTACT ? (
          <div>{SharePageStrings.MycontactsText}</div>
        ) : (
          <div>{SharePageStrings.NewContactText}</div>
        )}
      </div>

      <div className={styles.svNavContent}>
        <div className={styles.svNavContentBlock}>
          {contactOptionsList.map((option, key) => (
            <label key={key} className={styles.container}>
              <input
                type="radio"
                onChange={() => setContactOption(option.name)}
                checked={option.name === contactOption}
                name="radio"
              />

              <div className={styles.containerWrapper}>
                <div className={styles.blockImage}>
                  <img className={styles.imgStyle} src={option.img} alt={option.name} />
                  <img src={posterUrl} className={[option.imgStyle, styles.imgStyles].join(' ')} alt="" />
                </div>
                <div className={styles.titleBlock}>
                  <div className={styles.checkmark}>
                    <CheckMarkIcon />
                  </div>
                  <span>{option.name}</span>
                </div>
              </div>
            </label>
          ))}
        </div>
      </div>
      <div className={styles.svNavNavigation}>
        <button onClick={nextHandler} className={[styles.btn, material.shareButton].join(' ')}>
          Next
        </button>
      </div>
    </section>
  );

  const renderShareTextScreen = () => (
    <section className={[styles.svNav, styles.share].join(' ')}>
      <div className={styles.svNavTitle}>
        <span>Share Text Message</span>
        {/* <i className={[styles.icon, styles.questionIcon].join(' ')}>
          <QuestionIcon />
        </i> */}
      </div>
      <div className={styles.svNavSubTitle}>
        {shareScreenTexts.map((text, key) => (
          <div key={key}>{text}</div>
        ))}
      </div>
      <div className={styles.svNavContent}>
        <div className={styles.svNavContentBlock}>
          <label className={styles.container}>
            <div className={styles.containerWrapper}>
              <div className={styles.blockImage}>
                <img className={styles.imgStyle} src="/img/shareText.png" alt={''} />
              </div>
            </div>
            <div className={styles.subtitle}>Paste in text message</div>
          </label>
        </div>
      </div>
      <div className={styles.svNavNavigation}>
        <button
          style={isThumbnailCopied ? {} : {}}
          onClick={handleImageCopy}
          className={[styles.btn, isThumbnailCopied ? material.beforeCopy : material.shareButton].join(' ')}
        >
          {isThumbnailCopied ? (
            <>
              <Done style={{ marginBottom: -4, marginRight: 5 }} />
              <span>{SharePageStrings.SMSThumbnailCopied}</span>
            </>
          ) : (
            <span>Copy Video Image</span>
          )}
        </button>
        <button
          disabled={!hasBeenCopied}
          onClick={handleCta}
          className={[styles.btn, hasBeenCopied ? material.shareButton : styles.btnSecondary].join(' ')}
        >
          Open text Message
        </button>
      </div>
    </section>
  );

  const renderShareTextScreenInContacts = () => (
    <section className={[styles.svNav, styles.share].join(' ')}>
      <div className={styles.svNavTitle}>
        <span>Share via Text Message</span>
        {/* <i className={[styles.icon, styles.questionIcon].join(' ')}>
          <QuestionIcon />
        </i> */}
      </div>
      {/* <div className={styles.svNavSubTitle}>
        <div>{'choose contact to share text message'}</div>
      </div> */}
      <div className={styles.svNavNavigation}>
        <button onClick={() => handleSMSInContactsCTA()} className={[styles.btn, material.shareButton].join(' ')}>
          Open text Message
        </button>
      </div>
    </section>
  );

  return (
    <>
      {sharingSMSNotInContacts && shareStep === ShareSteps.STEP_1 && renderSelectContact()}
      {sharingSMSNotInContacts && shareStep === ShareSteps.STEP_2 && renderShareTextScreen()}
      {!sharingSMSNotInContacts && renderShareTextScreenInContacts()}
    </>
  );
};
