import { Provider } from 'react-redux';
import dynamic from 'next/dynamic';
import { StylesProvider, createGenerateClassName } from '@mui/styles';
import { ThemeProvider, StyledEngineProvider, CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider as StylesThemeProvider } from '@mui/styles';
import store from '../../store/store';
import { theme } from '../../theme';
import { Loader } from '../../common/components/Loader/Loader';
import { InitialContainer } from '../../common/components/InitialContainer/InitialContainer';
import { Layout } from '../../common/components/Layout/Layout';
import { Home } from '../Home/Home';
import { Share } from '../Share/Share';
import { Menu } from '../../common/components/Menu/Menu';

const CrispWithNoSSR = dynamic(() => import('../../../common/crisp'), { ssr: false });

export const Main = () => {
  const isWindow = typeof window !== 'undefined';
  if (!isWindow) {
    return <div></div>;
  }

  const generateClassName = createGenerateClassName({
    seed: 'top-golf-web-app',
  });

  return (
    <>
      <Provider store={store}>
        <StylesProvider generateClassName={generateClassName} injectFirst={false}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <StylesThemeProvider theme={theme}>
                <CssBaseline />
                <Loader />
                {process.env.NODE_ENV !== 'development' && <CrispWithNoSSR />}
                <Menu />
                <InitialContainer>
                  <Layout>
                    <Router>
                      <Switch>
                        <Route exact path="/">
                          <Home />
                        </Route>
                        <Route exact path="/share">
                          <Share />
                        </Route>
                      </Switch>
                    </Router>
                  </Layout>
                </InitialContainer>
              </StylesThemeProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </StylesProvider>
      </Provider>
    </>
  );
};
