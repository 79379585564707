import styles from '../../Share.module.css';
import { FC } from 'react';
import { Done } from '@mui/icons-material';
import { SharePageStrings } from '../../../../common/localization/en';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type ShareLinkProps = {
  methodName: string;
  buttonText: string;
  buttonHandler: () => void;
  isLinkCopied?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    // backgroundColor: 'rgba(79, 150, 255, 1)',
    color: theme.palette.secondary.contrastText,
    borderRadius: '50px',
    paddingTop: '12px',
    paddingBottom: '12px',
    height: 'auto',
  },
  text: {
    color: theme.palette.secondary.contrastText,
  },
}));

export const ShareLink: FC<ShareLinkProps> = ({ methodName, buttonText, buttonHandler, isLinkCopied }) => {
  const classes = useStyles();
  return (
    <section className={[styles.svNav, styles.share].join(' ')}>
      <div className={styles.svNavTitle}>
        <span>Share via {methodName}</span>
      </div>
      <div className={styles.svNavNavigation}>
        <button
          style={isLinkCopied ? { backgroundColor: '#17B790' } : {}}
          onClick={buttonHandler}
          className={[styles.btn, classes.button].join(' ')}
        >
          {isLinkCopied ? (
            <>
              <Done style={{ marginBottom: -4, marginRight: 5, color: 'white' }} />
              <span style={{ color: 'white' }}>{SharePageStrings.InstagramLinkCopied}</span>
            </>
          ) : (
            <span className={classes.text}>{buttonText}</span>
          )}
        </button>
      </div>
    </section>
  );
};
