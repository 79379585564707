export interface TimeProps {
  hours: number;
  minutes: number;
}

export function compareTime(item1: TimeProps, item2: TimeProps) {
  let result = item1.hours - item2.hours;

  if (result === 0) {
    result = item1.minutes - item2.minutes;
  }

  return result;
}

export const convertTZ = (date:string, tzString:string) =>{
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
}
