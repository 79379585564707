import { useEffect, useRef, useState, FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useTypedSelector } from '../../../store/store';
import { trackEvent } from '../../../utils/analytics/analytics';
import { AppRoutes, EventActions } from '../../constants';
import { useStyles } from './TeeTimesSlider.helper';

interface TeeTimesSliderProps {
  timeSlots: string[];
  selectedIndex: number;
  changeSelectedIndex: (newSelectedIndex: number) => void;
}

export const TeeTimesSlider: FC<TeeTimesSliderProps> = ({ timeSlots, selectedIndex, changeSelectedIndex }) => {
  const styles = useStyles();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const location = useLocation();
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const { venue } = useTypedSelector((state) => state.venue);

  useEffect(() => {
    if (!isFirstLoad) {
      const offset = (sliderRef.current?.querySelector(`.${styles.slide}:nth-child(${selectedIndex})`) as HTMLElement)
        ?.offsetLeft;

      sliderRef.current?.scroll({ left: offset, behavior: 'smooth' });
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (location.pathname === AppRoutes.home) {
      sliderRef.current?.scroll({ left: 999999 });
      setIsFirstLoad(false);
    }
  }, []);

  const slidesJsx = timeSlots.map((timeSlot, index) => {
    let slideClassName = styles.slide;
    let timeStyle = styles.time;
    const isActive = index === selectedIndex;

    if (isActive) {
      slideClassName = [styles.slide, styles.activeSlide].join(' ');
      timeStyle = styles.activeTime;
    }
    const handleClick = () => {
      trackEvent({ action: EventActions.selectShotTime, venueId: venue.id });
      changeSelectedIndex(index);
    };
    const [hour, minutes] = timeSlot.split(':').map((item) => parseInt(item));
    const remainder = hour % 12;
    const isAM = hour === 0 ? true : 24 - hour > 12;
    const formattedMinutes = minutes === 0 ? '00' : minutes;
    const formattedHours = remainder === 0 ? '12' : remainder;
    const logTime = `${formattedHours}:${formattedMinutes}${isAM ? ' AM' : ' PM'}`;

    return (
      <div className={slideClassName} onClick={handleClick} key={timeSlot}>
        <p className={timeStyle}>{logTime}</p>
      </div>
    );
  });

  return (
    <div className={styles.slider} ref={sliderRef}>
      {slidesJsx}
    </div>
  );
};
