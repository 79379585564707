import { FC } from 'react';

export const CloseIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" fill="none" viewBox="0 0 15 16">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M14.62 13.4a1.298 1.298 0 01-1.836 1.835L7.5 9.951l-5.284 5.284a1.294 1.294 0 01-1.836 0 1.298 1.298 0 010-1.836l5.284-5.284L.38 2.831A1.298 1.298 0 012.216.995L7.5 6.28 12.784.995a1.298 1.298 0 011.836 1.836L9.336 8.115 14.62 13.4z"
        clipRule="evenodd"
      />
    </svg>
  );
};
