import React, { FC } from 'react';

import { Rectangle } from './SmallSquareButton.style';
import { CloseIcon } from '../../../assets/CloseIcon';
import { ArrowLeftIcon } from '../../../assets/ArrowLeftIcon';
import { MenuIcon } from '../../../assets/MenuIcon';
import { CrispChatIcon } from '../../../assets/CrispChatIcon';

export enum SmallSquareButtonType {
  Close,
  Hamburger,
  ArrowLeft,
}

interface SmallSquareButtonProps {
  onButtonClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  buttonType: SmallSquareButtonType;
  transparent?: boolean;
  color?: string;
}

export const SmallSquareButton: FC<SmallSquareButtonProps> = ({ onButtonClick, buttonType, transparent, color }) => {
  let icon;
  switch (buttonType) {
    case SmallSquareButtonType.Close:
      icon = <CloseIcon />;
      break;
    case SmallSquareButtonType.Hamburger:
      icon = <CrispChatIcon />;
      break;
    case SmallSquareButtonType.ArrowLeft:
      icon = <ArrowLeftIcon />;
      break;
  }
  return (
    <Rectangle transparent={transparent} onClick={(e) => onButtonClick(e)}>
      {icon}
    </Rectangle>
  );
};
