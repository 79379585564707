import { getApiUrlForId } from '../../api/endpoints';
import { httpClient } from '../../services/httpClient/httpClient';

export const trackSharesCall = async (id: string, url: string, platform: string) => {
  try {
    return await httpClient.post<{ platform: string }, { success: boolean }>({
      url: getApiUrlForId(url, id),
      payload: { platform },
      requiresToken: false,
    });
  } catch (error) {
    console.log(error);
  }
};
