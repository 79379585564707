import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, useState } from 'react';

import styles from '../../Share.module.css';
// import { QuestionIcon } from '../../../../common/assets/QuestionIcon';
import { ArrowDownIcon } from '../../../../assets/ArrowDownIcon';
import { ShareIcon } from '../../../../assets/ShareIcon';
import { isChrome, isIOS, isSafari, browserName, browserVersion } from 'react-device-detect';
import { Toast, PredefinedMessages } from '../../../../common/components/Toast/Toast';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
  },
  text: {
    color: theme.palette.secondary.contrastText,
  },
  infoText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    gap: '3px',
    height: '12px',
    color: '#C1BCB5',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '10px',
    margin: '8px 0px',
    opacity: 0.8,
  },
  helperText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '28px',
    color: '#C1BCB5',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '10px',
    margin: '8px 0px',
    opacity: 0.8,
    textDecoration: 'underline',
  },
}));

type ShareInstagramProps = {
  copyHandler: () => void;
  onDownloadClick: () => void;
  copyInstagramLink: boolean;
};

export enum DownloadCommonStrings {
  saveVideoTocameraRoll = 'Save your video to your device camera roll',
  downloadYourVideo = 'Download Your Video',
  howto = 'How to download your video',
}

export enum SafariIosDownloadHelperStepsStrings {
  step1 = '1. Tap on the Download Your Video button',
  step2 = '2. Tap "Download" when prompted to',
  step3 = '3. Next to URL, tap on the',
  step4 = '4. After video is downloaded, tap on it',
  step5 = '5. Tap the ',
  step6 = '6. Tap “Save Video” to save to your phone ',
  icon = 'icon',
  shareOtions = 'icon to bring up share options',
}

export enum ChromeIosDownloadHelperStepsStrings {
  step1 = '1. Tap on the Download Your Video button',
  step2 = '2. Tap on the “',
  step3 = '3. Tap on “',
  step4 = '4. Tap on your video to view it',
  step5 = '5. Tap the ',
  step6 = '6. Tap “Save Video” to save video to your phone',
  shareOtions = 'icon to bring up share options',
  openInDownloads = 'Open in Downloads',
  option = '” option',
  openIn = 'Open in...',
  downloadTab = '” on the download tab',
}

export const ShareInstagram: FC<ShareInstagramProps> = ({ onDownloadClick, copyInstagramLink }) => {
  const classes = useStyles();
  const [isDownloadClicked, setIsDownloadClicked] = useState(false);
  const [isToast, setIsToast] = useState(false);
  const downloadHandler = () => {
    setIsDownloadClicked(true);
    onDownloadClick();
    if (isIOS && isSafari) {
      setIsToast(true);
    }
  };

  const message =
    isSafari && Number(browserVersion) >= 15
      ? PredefinedMessages.downloadSnackBarSafari15
      : PredefinedMessages.downloadSnackBackr;

  const renderIosHelperTexts = () => {
    if (isSafari) {
      return (
        <>
          <p className={classes.infoText}>{SafariIosDownloadHelperStepsStrings.step1} </p>
          <p className={classes.infoText}>{SafariIosDownloadHelperStepsStrings.step2} </p>
          <p className={classes.infoText}>
            {SafariIosDownloadHelperStepsStrings.step3} <ArrowDownIcon /> {SafariIosDownloadHelperStepsStrings.icon}
          </p>
          <p className={classes.infoText}>{SafariIosDownloadHelperStepsStrings.step4} </p>
          <p className={classes.infoText}>
            {SafariIosDownloadHelperStepsStrings.step5}
            <ShareIcon /> {SafariIosDownloadHelperStepsStrings.shareOtions}{' '}
          </p>
          <p className={classes.infoText}>{SafariIosDownloadHelperStepsStrings.step6} </p>
        </>
      );
    } else if (isChrome) {
      return (
        <>
          <p className={classes.infoText}>{ChromeIosDownloadHelperStepsStrings.step1} </p>
          <p className={classes.infoText}>
            {ChromeIosDownloadHelperStepsStrings.step2}
            <span style={{ color: 'white' }}>{ChromeIosDownloadHelperStepsStrings.openIn}</span>{' '}
            {ChromeIosDownloadHelperStepsStrings.downloadTab}{' '}
          </p>
          <p className={classes.infoText}>
            {ChromeIosDownloadHelperStepsStrings.step3}{' '}
            <span style={{ color: 'white' }}>{ChromeIosDownloadHelperStepsStrings.openInDownloads}</span>{' '}
            {ChromeIosDownloadHelperStepsStrings.option}
          </p>
          <p className={classes.infoText}>{ChromeIosDownloadHelperStepsStrings.step4} </p>
          <p className={classes.infoText}>
            {ChromeIosDownloadHelperStepsStrings.step5}
            <ShareIcon /> {ChromeIosDownloadHelperStepsStrings.shareOtions}{' '}
          </p>
          <p className={classes.infoText}>{ChromeIosDownloadHelperStepsStrings.step6} </p>
        </>
      );
    }
  };

  return (
    <section className={[styles.svNav, styles.share].join(' ')}>
      <Toast
        message={message}
        open={isToast}
        setOpen={setIsToast}
        position={
          message === PredefinedMessages.downloadSnackBarSafari15
            ? { vertical: 'bottom', horizontal: 'left' }
            : { vertical: 'top', horizontal: 'right' }
        }
      />
      <div className={styles.svNavTitle}>
        <span>{DownloadCommonStrings.downloadYourVideo}</span>
        {/* <QuestionIcon /> */}
      </div>
      <div className={styles.svNavSubTitle}>
        <div>{DownloadCommonStrings.saveVideoTocameraRoll}</div>
      </div>
      <div className={styles.svNavNavigation}>
        <button onClick={downloadHandler} className={[styles.btn, classes.button].join(' ')}>
          {DownloadCommonStrings.downloadYourVideo}
        </button>
      </div>
      <p className={classes.helperText} onClick={() => setIsDownloadClicked(true)}>
        {isIOS && DownloadCommonStrings.howto}
      </p>
      {isDownloadClicked && isIOS && <>{renderIosHelperTexts()}</>}
    </section>
  );
};
