import { FC } from 'react';

import { useStyles } from './CardTitles.helper';

interface CardTitlesProps {
  title?: string;
  subtitle?: string;
}

export const CardTitles: FC<CardTitlesProps> = ({ title, subtitle }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {title && <div className={classes.title}>{title}</div>}
      {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
    </div>
  );
};
