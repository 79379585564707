import React, { FC } from 'react';
import { StoryDetails } from '../../../api/models/auth';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { UnifiedPreview } from './UnifiedPreview/UnifiedPreview';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { useAppDispatch } from '../../../store/store';
import { setShareStory } from '../../../store/slices/stories';

interface PreviewProps {
  posterUrl: string;
  storyDetails?: StoryDetails;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    marginTop: '10px',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  title1: {
    color: '#FFFFFF',
    fontSize: '22px',
    fontWeight: 'bold',
    textAlign: 'center',
    opacity: 0.5,
  },
  title2: {
    color: theme.palette.primary.main,
    fontSize: '22px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  subtitle: {
    color: '#FFFFFF',
    opacity: 0.5,
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'center',
    alignSelf: 'center',
    marginBottom: '10px',
  },
  contentWrapper: {
    borderRadius: '16px',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingBottom: '2px',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '37%',
    },
  },
  backArrowWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '5px',
    marginBottom: '8px',
    opacity: 0.5,
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '37%',
    },
  },
  backArrowIcon: {
    transform: 'scale(1,-1)',
    marginRight: '4px',
  },
  moreVideosText: {
    fontSize: '12px',
  },
}));

export const Preview: FC<PreviewProps> = ({ posterUrl, storyDetails }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const handleClick = () => {
    dispatch(setShareStory(null));
  };
  return (
    <div className={classes.container}>
      <div className={classes.backArrowWrapper} onClick={handleClick}>
        <span>
          <KeyboardReturnIcon className={classes.backArrowIcon} />{' '}
        </span>
        <span className={classes.moreVideosText}>More Videos</span>
      </div>
      <div className={classes.contentWrapper}>
        <UnifiedPreview storyDetails={storyDetails} posterUrl={posterUrl} />
      </div>
    </div>
  );
};
