import styled from 'styled-components';

interface ModalOverlayProps {
  alignItems: string;
  semiTransparentBgr: boolean;
}
export const ModalOverlay = styled.div<ModalOverlayProps>`
  position: fixed;
  background-color: ${({semiTransparentBgr}) => semiTransparentBgr ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.95)'};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
  justify-content: center;
  overflow: hidden;
  z-index: 101;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 320px;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const CloseIconWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  z-index: 11;
`;
