export enum HomePageStrings {
  TimeOfShot = 'Time of your shot ',
  estimated = '(estimated)',
  BySelecting = 'I am the person in this video, or I have permission from this person in this video to share it. I will not use it for commercial purposes.  I agree to these',
  TermsOfService = 'Terms of Use & Privacy Policy',
  Select = 'Select',
}

export enum SharePageStrings {
  Title = 'Every share gets you closer to your reward',
  TitleFirstText = 'Share with',
  TitleSecondText = 'Friends / Followers',
  DownloadButtonText = 'Download',
  MyProfileBtn = 'My Profile',
  CopyLinkButtonText = 'Copy Link',
  CopiedLinkButtonText = 'Link Copied',
  ShareVideoHint = 'Instagram, Snapchat, Tiktok',
  ShareLinkHint = 'Facebook, Whatsapp, SMS',
  BackDropHint = 'Your video will be accessible here',
  DownloadPopupText = 'To download your Story copy link and paste it in the Safari browser',
  SmsButtonText = 'Share via Text Message',
  EmailButtonText = 'Share via Email',
  FacebookButtonText = 'Share via Facebook',
  LinkedinButtonText = 'Share via LinkedIn',
  TwitterButtonText = 'Share via Twitter',
  InstagramButtonText = 'Share to bio',
  InstagramPreviewTitle = 'Add your video link to profile Website field:',
  InstagramBio = 'Help my team, click on the link:',
  InstagramBioEditBtn = 'Edit Profile',
  SmsMessage = 'How is the season going?',
  SmsTitle = 'Your video message',
  SmsMessageTime = 'Seen 1:30pm',
  VideoCreatedPopupOnce = 'First Video Earned You',
  VideoCreatedPopupOther = 'This Video Earned You',
  RewardsPointsModalBtn = 'Earn more points',
  Points = 'points',
  Congrats = 'Congrats!',
  CopyThumbnail = 'Copy Video Tumbnail',
  ThumbnailCopied = 'Copied - Paste into email body',
  SMSThumbnailCopied = 'Copied - Paste into SMS',
  InstagramLinkCopied = 'Link copied',
  ShareText = 'Share via Text Message',
  ShareEmail = 'Share Via Email',
  ShareAsText = 'Share to text message',
  ShareAsEmail = 'Send as email message',
  ClickLinkBelow = 'Click link below',
  OverlayText = 'Overlay Text',
  IntroText = 'Intro text',
  VideoMessage = 'Share Message',
  ButtonLink = 'Button Link',
  ButtonText = 'Button Text',
  Thumbnail = 'Thumbnail',
  Public = 'Public',
  Cancel = 'Cancel',
  Save = 'Save',
  PreviewTitle = 'Customize &',
  PreviewTitlept2 = 'Share',
  PreviewSubtitle = 'Video perfomance is tracked',
  ShareWith = 'Share With',
  Share = 'Share',
  NewContactText = `‘New Contact’ means you have never exchanged messages before.`,
  MycontactsText = '‘My contact’ means you have exchanged messages before.',
  Shareyourshot = 'Share your shot',
  WhereDownloading = 'Where are you downloading?',
  HowToDownload = 'How to download your video',
}

export enum NoContent {
  title = 'No Videos Available',
  caption = 'Check back later.',
}
