import styles from '../../Share.module.css';
import {QuestionIcon} from '../../../../common/assets/QuestionIcon';
import { FC, useState, useEffect } from 'react';
import { copyHtml, handleCopyFallback } from '../../../../utils';
import { isChrome, isEdge, isIOS, isMacOs, isSamsungBrowser } from 'react-device-detect';
import { Done } from '@mui/icons-material';
import { SharePageStrings } from '../../../../common/localization/en';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
  },
  text: {
    color: theme.palette.secondary.contrastText,
  },
}));

type ShareEmailProps = {
  posterUrl: string;
  storyLink: string;
  handleCta: () => void;
  introText: string;
};

export const ShareEmail: FC<ShareEmailProps> = ({ posterUrl, handleCta, storyLink, introText }) => {
  const [isImageCopied, setIsImageCopied] = useState<boolean>(false);
  // const [hasBeenCopied, setHasBeenCopied] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (isImageCopied) {
      setTimeout(() => {
        setIsImageCopied(false);
      }, 6000);
    }
  }, [isImageCopied]);

  const handleMailImageCopy = () => {
    if (isIOS || isMacOs) {
      copyHtml(posterUrl, storyLink, (isChrome && !isIOS) || isEdge || isSamsungBrowser, introText)
        .then(() => {
          setIsImageCopied(true);
          // setHasBeenCopied(true);
        })
        .catch((err) => {
          handleCopyFallback(posterUrl, storyLink)
            .then(() => {
              setIsImageCopied(true);
              // setHasBeenCopied(true);
              console.log(err);
            })
            .catch((err) => {
              console.log(err);
            });
        });
    } else {
      handleCopyFallback(posterUrl, storyLink)
        .then(() => {
          setIsImageCopied(true);
          // setHasBeenCopied(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleImageCopy = () => {
    handleMailImageCopy();
  };

  return (
    <section className={[styles.svNav, styles.share].join(' ')}>
      <div className={styles.svNavTitle}>
        <span>Share Email</span>
        <i className={[styles.icon, styles.questionIcon].join(' ')}>
          <QuestionIcon />
        </i>
      </div>
      <div className={styles.svNavSubTitle}>
        <div>1. Copy the Video Image</div>
        <div>2. Open pre-filled Text Message</div>
        <div>3. Paste the Video Image above the Text Message</div>
      </div>
      <div className={styles.svNavContent}>
        <div className={styles.svNavContentBlock}>
          <label className={styles.container}>
            <div className={styles.containerWrapper}>
              <div className={styles.blockImage}>
                <img className={styles.imgStyle} src="/img/shareEmail.png" alt="share email" />
                <img className={styles.imgStyleEmail} src={posterUrl} alt="share email" />
              </div>
            </div>
            <div className={styles.subtitle}>Paste in Email</div>
          </label>
        </div>
      </div>
      <div className={styles.svNavNavigation}>
        <button
          onClick={handleImageCopy}
          style={isImageCopied ? { backgroundColor: '#17B790' } : {}}
          className={[styles.btn, classes.button].join(' ')}
        >
          {isImageCopied ? (
            <>
              <Done style={{ marginBottom: -4, marginRight: 5, color: 'white' }} />
              <span style={{ color: 'white' }}>{SharePageStrings.ThumbnailCopied}</span>
            </>
          ) : (
            <span className={classes.text}>copy Video thumbnail</span>
          )}
        </button>
        <button
          disabled={!isImageCopied}
          onClick={handleCta}
          className={[styles.btn, isImageCopied ? classes.button : styles.btnSecondary].join(' ')}
        >
          Open Mail
        </button>
      </div>
    </section>
  );
};
