import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { UploadEndpoints } from '../../api/endpoints';
// import { StoryDetails } from '../../api/models/auth';
import { Frame } from '../../utils';
import { httpClient } from '../../services/httpClient/httpClient';
import { UploadProcessDTO, UploadProcessStep } from '../storeModels';

export interface UploadUrlApiModel {
    downloadUrl?: string;
    url: string;
    method: string;
    headers: {
      additionalProp1: string;
      additionalProp2: string;
      additionalProp3: string;
    };
  }
  

export const uploadInitialState: UploadProcessDTO = {
  currentStep: UploadProcessStep.UploadVideo,
  cellPhone: '',
  email: '',
  handle: '',
  password: '',
  uploadingProgress: 0,
  supportedMimeType: 'video/mp4',
  fileSelected: false,
  thumbnailFrameurl: null,
};

export const createUploadUrl = async (mimeType?: string) => {
  try {
    return httpClient.post<undefined, UploadUrlApiModel>({
      url: `${UploadEndpoints.CreateUploadUrl}?mimeType=${mimeType}`,
      requiresToken: true,
    });
  } catch (error) {
    console.log('uploaded');
    return console.log(error);
  }
};

export const createUploadThumbnailUrl = async (mimeType?: string) => {
  try {
    return httpClient.post<undefined, UploadUrlApiModel>({
      url: `${UploadEndpoints.CreateThumbnailUploadUrl}?mimeType=${mimeType}`,
      requiresToken: true,
    });
  } catch (error) {
    console.log('uploaded');
    return console.log(error);
  }
};

interface UploadVideoRequest {
  options: UploadUrlApiModel;
  data: string;
  onUploadProgress?: (event: ProgressEvent) => void;
}

type UploadThumbnailRequest = Omit<UploadVideoRequest, 'onUploadProgress'>;

export const uploadVideo = createAsyncThunk(
  'upload/uploadVideo',
  async ({ options, data, onUploadProgress }: UploadVideoRequest) => {
    if (!data) {
      return;
    }
    try {
      const file = await fetch(data).then((r) => r.blob());

      const res = await axios.put<typeof options, undefined>(options.url, file, {
        headers: options.headers,
        onUploadProgress,
      });
      console.log(res);
      return res;
    } catch (error) {
      console.log(error);
    }
  },
);

export const uploadThumbnail = createAsyncThunk(
  'upload/uploadThumbnail',
  async ({ options, data }: UploadThumbnailRequest) => {
    if (!data) {
      return;
    }
    try {
      const file = await fetch(data).then((r) => r.blob());
      return await axios.put<typeof options, undefined>(options.url, file, {
        headers: options.headers,
      });
    } catch (error) {
      console.log(error);
    }
  },
);

interface UploadVideoAsFileRequest {
  options: UploadUrlApiModel;
  data: File;
  onUploadProgress?: (event: ProgressEvent) => void;
}
export const uploadVideoAsFile = createAsyncThunk(
  'upload/uploadVideoAsFile',
  async ({ options, data, onUploadProgress }: UploadVideoAsFileRequest) => {
    if (!data) {
      return;
    }
    try {
      const res = await axios.put<typeof options, undefined>(options.url, data, {
        headers: options.headers,
        onUploadProgress,
      });
      console.log(res);
      return res;
    } catch (error) {
      console.log(error);
    }
  },
);

export const uploadVideoUnsuportedFile = createAsyncThunk(
  'upload/uploadVideoUnsupportedFile',
  async ({ options, data, onUploadProgress }: UploadVideoAsFileRequest) => {
    if (!data) {
      return;
    }
    try {
      const res = await axios.put<typeof options, undefined>(options.url, data, {
        headers: options.headers,
        onUploadProgress,
      });
      console.log(res);
      return res;
    } catch (error) {
      console.log(error);
    }
  },
);

export interface UploadStoryDetails {
  metaTitle: string;
  metaDescription: string;
  videoText: string;
  ctaBtnText: string;
  ctaBtnLinkUrl: string | null;
  videoMessage: string;
}

const uploadSlice = createSlice({
  name: 'upload',
  initialState: uploadInitialState,
  reducers: {
    changeStep(state, action: PayloadAction<UploadProcessStep>) {
      state.currentStep = action.payload;
    },
    setthumbnailFrameurl(state, action: PayloadAction<Frame>) {
      state.thumbnailFrameurl = action.payload;
    },
    setCellPhone(state, action: PayloadAction<string>) {
      state.cellPhone = action.payload;
    },
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    setHandle(state, action: PayloadAction<string>) {
      state.handle = action.payload;
    },
    setPassword(state, action: PayloadAction<string>) {
      state.password = action.payload;
    },
    setProgress(state, action: PayloadAction<number>) {
      if (action.payload > 95) {
        return;
      }
      state.uploadingProgress = action.payload;
    },
    setMimeType(state, action: PayloadAction<string>) {
      state.supportedMimeType = action.payload;
    },
    setFileSelected(state, action: PayloadAction<boolean>) {
      state.fileSelected = action.payload;
    },
    reset: () => uploadInitialState,
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(uploadVideo.pending, (state) => {
      state.currentStep = UploadProcessStep.VideoUploading;
    });
    reducersBuilder.addCase(uploadVideoAsFile.pending, (state) => {
      state.currentStep = UploadProcessStep.VideoUploading;
    });
    reducersBuilder.addCase(uploadVideoUnsuportedFile.pending, (state) => {
      state.currentStep = UploadProcessStep.PrepareVideo;
    });
    reducersBuilder.addCase(uploadVideoUnsuportedFile.fulfilled, (state) => {
      state.uploadingProgress = 100;
    });
  },
});

export const {
  changeStep,
  setEmail,
  setCellPhone,
  setHandle,
  setPassword,
  reset,
  setProgress,
  setMimeType,
  setFileSelected,
  setthumbnailFrameurl,
} = uploadSlice.actions;
export default uploadSlice.reducer;
