import { useEffect, useMemo, useState } from 'react';

import { TeeTimesSlider } from '../../common/components/TeeTimesSlider/TeeTimesSlider';
import { ThumbnailSlider } from '../../common/components/ThumbnailSlider/ThumbnailSlider';
import { extendStoriesWithTimeSlots, findLastIndex, isUnique } from '../../utils/general';

import { makeStyles } from '@mui/styles';
import { useTypedSelector } from '../../store/store';
import { StoriesWithDateTime } from '../../api/models/stories';
import { TopBar } from '../../common/components/TopBar/TopBar';
// import { AdPopup } from '../../common/components/AdPopup/AdPopup';
import { HomePageStrings } from '../../common/localization/en';
import { SelectBoxTOS } from '../../common/components/SelectBoxTOS/SelectBoxTOS';
import { IncentiveAd } from '../../common/components/IncentiveAd/IncentiveAd';
import { Welcome } from '../../common/components/Welcome/Welcome';
import { useLocation } from 'react-router-dom';
import { trackEvent, trackPageView } from '../../utils/analytics/analytics';
import { EventActions } from '../../common/constants';
import CustomNoContent from '../../common/components/NoContent/NoContent';

const useStyles = makeStyles(() => ({
  main: {
    width: '100%',
    minWidth: '400px',
    padding: '24px',
  },

  title: {
    color: '#fff',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '800',
  },

  subtitle: {
    color: '#fff',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '400',
  },

  teeTimesContainer: {
    margin: '15px 0',
  },

  thumbnailsContainer: {
    margin: '40px 0',
  },
}));

export const Home = () => {
  const increment = 15;

  const styles = useStyles();
  const { items } = useTypedSelector((state) => state.story.stories);
  const { selectedStory } = useTypedSelector((state) => state.story);
  const { venue } = useTypedSelector((state) => state.venue);
  const thumbnails = useMemo<StoriesWithDateTime[]>(() => extendStoriesWithTimeSlots(items, increment), [items]);
  const timeSlots = useMemo(() => thumbnails.map((item) => item.timeSlot).filter(isUnique), [thumbnails]);

  const [selectedTimeSlotIndex, setSelectedTimeSlotIndex] = useState(
    selectedStory ? timeSlots.findIndex((item) => item === selectedStory.timeSlot) : timeSlots.length - 1,
  );
  const [selectedThumbnailIndex, setSelectedThumbnailIndex] = useState(
    selectedStory ? thumbnails.findIndex((item) => item.id === selectedStory.id) : thumbnails.length - 1,
  );

  const [isNotFirstTimeUser, setIsNotFirstTimeUser] = useState(localStorage.getItem('fvisit') || '');
  const location = useLocation();
  // const [isAdpopOpen, setIsAdPopupOpen] = useState(false);

  useEffect(() => {
    trackPageView({ url: location.pathname, venueId: venue.id });
    const isFirstTimeVisit = !localStorage.getItem('first_time_visit');
    if (isFirstTimeVisit) {
      trackEvent({ action: EventActions.firstTimeVisit, venueId: venue.id });
      localStorage.setItem('first_time_visit', 'false');
    }
  }, []);

  useEffect(() => {
    if (thumbnails && thumbnails.length && !selectedStory) {
      setSelectedTimeSlotIndex(timeSlots.length - 1);
      setSelectedThumbnailIndex(thumbnails.length - 1);
    }
  }, [thumbnails, timeSlots, selectedStory]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsAdPopupOpen(true);
  //   }, 5000);
  // }, []);
  const closeDialog = () => {
    setIsNotFirstTimeUser('true');
  };

  function changeSelectedTimeSlotIndex(newIndex: number) {
    const lastThubnailInTimeSlotIndex = findLastIndex(thumbnails, (item) => item.timeSlot === timeSlots[newIndex]);

    if (lastThubnailInTimeSlotIndex > -1) {
      setSelectedThumbnailIndex(lastThubnailInTimeSlotIndex);
    }

    setSelectedTimeSlotIndex(newIndex);
  }

  function changeSelectedThumbnailIndex(newIndex: number) {
    const timeSlotOfThumbnailIndex = timeSlots.indexOf(thumbnails[newIndex].timeSlot);

    if (timeSlotOfThumbnailIndex > -1) {
      setSelectedTimeSlotIndex(timeSlotOfThumbnailIndex);
    }

    setSelectedThumbnailIndex(newIndex);
  }
  // const onCloseadDialog = () => {
  //   setIsAdPopupOpen(false);
  // };
  if (!isNotFirstTimeUser) {
    return <Welcome closeDialog={closeDialog} />;
  }

  return (
    <>
      <TopBar />
      {/* <AdPopup adDialogOpen={isAdpopOpen} closeAdDialog={onCloseadDialog} /> */}
      <main className={styles.main}>
        <h1 className={styles.title}>
          {HomePageStrings.TimeOfShot}
          <span className={styles.subtitle}>{HomePageStrings.estimated}</span>
        </h1>
        <div className={styles.teeTimesContainer}>
          <TeeTimesSlider
            timeSlots={timeSlots}
            selectedIndex={selectedTimeSlotIndex}
            changeSelectedIndex={changeSelectedTimeSlotIndex}
          />
        </div>
        <div className={styles.thumbnailsContainer}>
          {thumbnails.length ? (
            <ThumbnailSlider
              thumbnails={thumbnails}
              selectedIndex={selectedThumbnailIndex}
              changeSelectedIndex={changeSelectedThumbnailIndex}
            />
          ) : (
            <CustomNoContent />
          )}
        </div>
        {thumbnails.length ? (
          <SelectBoxTOS
            activeThumbnail={thumbnails[selectedThumbnailIndex]}
            activeTimeSlot={timeSlots[selectedTimeSlotIndex]}
          />
        ) : null}
      </main>
      <IncentiveAd />
    </>
  );
};
