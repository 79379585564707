import { useState, FC, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Button, Theme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { HomePageStrings } from '../../localization/en';
import { StoriesApiModel } from '../../../api/models/stories';
import { setSelectedStory, setShareStory } from '../../../store/slices/stories';
import { useAppDispatch, useTypedSelector } from '../../../store/store';
import { AppRoutes, EventActions } from '../../constants';
import { trackEvent } from '../../../utils/analytics/analytics';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  bySelecting: {
    color: '#B4B4B4',
    fontWeight: 400,
    fontSize: '10px',
    // whiteSpace: 'nowrap',
  },
  TermsOfService: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '11px',
    whiteSpace: 'nowrap',
    marginLeft: '3px',
  },
  checkBox: {
    border: '1px solid white',
    backgroundColor: 'black',
    marginRight: '10px',
  },
  Button: {
    height: '48px',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:disabled': {
      backgroundColor: '#C1BCB5',
    },
  },
  error: {
    color: 'red',
    opacity: 0.7,
  },
  ButtonDisabled: {
    backgroundColor: '#C1BCB5',
  },
  checkBoxWrapper: {
    border: '1px solid red',
    borderRadius: '3px',
  },
}));

interface SelectBoxTOSProps {
  activeThumbnail: StoriesApiModel;
  activeTimeSlot: string;
}

export const SelectBoxTOS: FC<SelectBoxTOSProps> = ({ activeThumbnail, activeTimeSlot }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { venue } = useTypedSelector((state) => state.venue);

  const [error, setIsError] = useState(false);
  const history = useHistory();
  const tosChecked = localStorage.getItem('TOSCheck');
  const [checked, setChecked] = useState(!!tosChecked);
  useEffect(() => {
    if (tosChecked) {
      setChecked(true);
    }
  }, [tosChecked]);

  const handleChange = (event: { target: { checked: boolean } }) => {
    if (event.target.checked) {
      if (!tosChecked) {
        localStorage.setItem('TOSCheck', 'true');
        trackEvent({ action: EventActions.acceptTos, venueId: venue.id });
      }
      setIsError(false);
    }
    setChecked(event.target.checked);
  };
  const handleClick = () => {
    if (checked) {
      dispatch(setShareStory(activeThumbnail));

      const hours = Number(activeTimeSlot.split('.')[0]);
      const minutes = Number(activeTimeSlot.split('.')[1]);
      dispatch(
        setSelectedStory({
          ...activeThumbnail,
          timeSlot: activeTimeSlot,
          hours,
          minutes,
        }),
      );
      trackEvent({ action: EventActions.selectVideo, venueId: venue.id, videoId: activeThumbnail.id });
      history.push(AppRoutes.share);
    } else {
      setIsError(true);
    }
  };

  return (
    <Grid container alignItems="center" justifyContent="center" spacing={2}>
      <Grid item xs={12} className={classes.container}>
        <span>
          <input type="checkbox" onChange={handleChange} checked={checked} className={classes.checkBox} />
        </span>
        <span className={[classes.bySelecting, error ? classes.error : ''].join(' ')}>
          {HomePageStrings.BySelecting}
          <a
            href="https://www.socialvenu.com/users-terms"
            target="_blank"
            className={[classes.TermsOfService, error ? classes.error : ''].join(' ')}
            rel="noreferrer"
          >
            {HomePageStrings.TermsOfService}
          </a>
        </span>
      </Grid>
      <Grid item xs={12} md={6}>
        <Button className={classes.Button} onClick={handleClick} disabled={error} variant="contained" fullWidth>
          {HomePageStrings.Select}
        </Button>
      </Grid>
    </Grid>
  );
};
