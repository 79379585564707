import { useEffect, useState } from 'react';
import { Box, Button, DialogContentText } from '@mui/material';

import { useAppDispatch, useTypedSelector } from '../../store/store';
import { Modal } from '../../common/components/Modal/Modal';
import { AppRoutes, getApiUrlWithIdQueryParam } from '../../common/constants';
import { Preview } from './Preview/Preview';

import { copyToClipboard } from '../../services/utilities';
import { getVideoPoster } from '../../utils';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { setShareStory } from '../../store/slices/stories';
import { TopBar } from '../../common/components/TopBar/TopBar';
import { IncentiveAd } from '../../common/components/IncentiveAd/IncentiveAd';
import { trackPageView } from '../../utils/analytics/analytics';

export const Share = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { id: shareStoryId }: { id?: string } = params;

  const { shareStory } = useTypedSelector((state) => state.story);

  const [copied, setCopied] = useState(false);
  const [isIphoneDownloadPopup, setIsIphoneDownloadPopup] = useState(false);
  const history = useHistory();
  const location = useLocation()
  useEffect(()=>{
    trackPageView({url:location.pathname, venueId:shareStory?.venueId, videoId:shareStory?.id})
  },[])

  useEffect(() => {
    if (!shareStory) {
      history.push(AppRoutes.home);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareStory]);

  useEffect(() => {
    return () => {
      dispatch(setShareStory(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseDownloadPopupHandler = () => {
    setIsIphoneDownloadPopup(false);
  };

  const copyLink = (pageUrl: string) => {
    if (window && (shareStoryId?.length ?? 0) > 0) {
      copyToClipboard(
        window.location.origin + getApiUrlWithIdQueryParam(pageUrl, shareStory ? shareStory.shortcode : shareStoryId),
      ).then(() => setCopied(true));
    }
  };

  const onCopyLinkCurrentPage = () => {
    copyLink(AppRoutes.share);
    onCloseDownloadPopupHandler();
  };

  if (!shareStory) {
    return <div></div>;
  }

  return (
    <>
      <TopBar />
      {isIphoneDownloadPopup && (
        <Modal onClose={onCloseDownloadPopupHandler} isCloseButton={false}>
          <Box position="relative" height="214px" padding="16px 0" margin="24px" bgcolor="#1C1D22" borderRadius="10px">
            <Box margin="0 25px 46px 25px">
              <DialogContentText>download</DialogContentText>
            </Box>
            <Box marginX={2} marginTop={4}>
              <Button size="large" variant="contained" color="primary" fullWidth onClick={onCopyLinkCurrentPage}>
                popup
              </Button>
            </Box>
          </Box>
        </Modal>
      )}

      <div>
        <Preview
          posterUrl={shareStory?.thumbnailUrl || getVideoPoster(shareStory?.url)}
          storyDetails={shareStory?.details}
        />
        <IncentiveAd />
      </div>
    </>
  );
};
