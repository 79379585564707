import { Button, Container, Dialog, DialogContent, DialogActions, useTheme, useMediaQuery } from '@mui/material';
import { useTypedSelector } from '../../../store/store';
import { PositionType, useStyles } from './Welcome.helper';
import Image from 'next/image';
import { ArrowRightIcon } from '../../assets/ArrowRightIcon';
import { FC } from 'react';
import { CardTitles } from '../CardTitles/CardTitles';
import { trackEvent } from '../../../utils/analytics/analytics';
import { EventActions } from '../../constants';
import { getBestImage } from '../../../services/utilities';

interface WelcomeProps {
  closeDialog: () => void;
}

export const Welcome: FC<WelcomeProps> = ({ closeDialog }) => {
  const { venue, venueAttributes } = useTypedSelector((state) => state.venue);
  // const venueMedia = venueAttributes.properties?.['webapp.cards-welcome'];
  const theme = useTheme();
  const matches = {
    tablet: useMediaQuery(theme.breakpoints.between('sm', 'lg')),
    mobile: useMediaQuery(theme.breakpoints.down('sm')),
    desktop: useMediaQuery(theme.breakpoints.up('lg')),
  };
  const venueMedia =
    getBestImage(venueAttributes.properties?.['webapp.cards-welcome'], matches) ??
    venueAttributes.properties?.['webapp.welcome-video'];

  const onCloseEmailPopupHandler = () => {
    // setIsEmailPopup(false);
  };
  const onLearnMoreClick = () => {
    trackEvent({ action: EventActions.getStarted, venueId: venue.id });
    localStorage.setItem('fvisit', 'true');
    closeDialog();
  };
  const classes = useStyles();
  const alignment = venueAttributes.properties?.['webapp.cards-welcome']?.alignment;
  let titlePosition: string = 'top';
  if (alignment === 'center') {
    titlePosition = alignment;
  } else if (alignment === 'end') {
    titlePosition = alignment;
  }
  return (
    <Dialog classes={{ paper: classes.paper }} open={true} fullScreen onClose={onCloseEmailPopupHandler}>
      <DialogContent className={classes.content}>
        <div className={classes.logo}>
          <Image layout="fill" src={venue.logo || '/logo-sv.svg'} alt={venue.name} />
        </div>
        <Container className={classes.container}>
          {venueMedia && (
            <div className={classes.ImageWrapper}>
              <img
                src={venueMedia || '/adImg.png'}
                alt="background"
                style={{ objectFit: 'cover', width: '100%', height: '100%' }}
              />
            </div>
          )}
          <div className={classes[titlePosition as PositionType]}>
            <CardTitles
              title={venueAttributes.properties?.['webapp.cards-welcome']?.title}
              subtitle={venueAttributes.properties?.['webapp.cards-welcome']?.subtitle}
            />
          </div>
        </Container>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <div className={classes.ctaButton}>
          <Button className={classes.button} color="primary" variant="contained" fullWidth onClick={onLearnMoreClick}>
            {'Get Started'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
