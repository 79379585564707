import { PaginationModel, StoriesDTO } from '../storeModels';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSubdomain } from '../../services/utilities';
import { httpClient } from '../../services/httpClient/httpClient';
import { getApiUrlForId, StoryEndpoints } from '../../api/endpoints';
import { StoriesApiModel, StoriesWithDateTime } from '../../api/models/stories';
import { PaginationRequest } from '../../common/CommonTypes';
import { StoryDetails } from '../../api/models/auth';

export enum StoryStatuses {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  MODERATED = 'MODERATED',
}

export const storiesInitialState: StoriesDTO = {
  stories: {
    items: [],
    totalPages: 0,
    totalItems: 0,
    page: 1,
    size: 10,
    isLoading: true,
  },
  currentStory: null,
  shareStory: null,
  shareStoryLoading: false,
  returnUrl: '',
  newStoriesCount: 0,
  mainCategory: null,
  filterPublicId: '',
  selectedTags: null,
  selectedStory: null,
};

type GetStoriesRequest = {
  venueId: string;
  pageable: PaginationRequest;
};

interface UpdateStoryByIdRequest {
  id: string;
  status?: StoryStatuses;
  featured?: boolean;
  deleted?: boolean;
  isPublic?: boolean;
  details?: StoryDetails;
  thumbnailUrl?: string;
}

export const updateShareStoryById = createAsyncThunk(
  'videos/updateShareStoryById',
  async (params: UpdateStoryByIdRequest, { rejectWithValue }) => {
    try {
      return await httpClient.put<UpdateStoryByIdRequest, StoriesApiModel>({
        url: getApiUrlForId(StoryEndpoints.GetStoryById, params.id),
        requiresToken: true,
        payload: params,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface GetStoryByIdRequest {
  id: string;
}

export const getStoryByIdRequest = async (id: string) => {
  return await httpClient.get<GetStoryByIdRequest, StoriesApiModel>({
    url: getApiUrlForId(StoryEndpoints.GetStoryById, id),
    requiresToken: false,
  });
};

export const getStoryById = createAsyncThunk(
  'videos/getStoryById',
  async ({ id }: GetStoryByIdRequest, { rejectWithValue }) => {
    try {
      return getStoryByIdRequest(id);
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface GetStoriesRequestWithPagination extends PaginationRequest {
  accountId?: string;
}

export const getStories = createAsyncThunk('videos/today', async (options: GetStoriesRequest, { rejectWithValue }) => {
  const subdomain = getSubdomain();
  try {
    return await httpClient.get<GetStoriesRequestWithPagination, PaginationModel<StoriesApiModel>>({
      url: getApiUrlForId(StoryEndpoints.StoryToday, subdomain),
      requiresToken: false,
      params: {
        accountId: options.venueId,
        size: options.pageable.size,
        sort: options.pageable.sort,
      },
    });
  } catch (error) {
    // @ts-ignore
    return rejectWithValue(error.response.data.message);
  }
});
const storySlice = createSlice({
  name: 'story',
  initialState: storiesInitialState,
  reducers: {
    reset: (state) => ({ ...storiesInitialState, selectedStory: state.selectedStory }),
    setShareStory: (state, action: PayloadAction<StoriesApiModel | null>) => {
      state.shareStory = action.payload;
    },
    setSelectedStory: (state, action: PayloadAction<StoriesWithDateTime>) => {
      state.selectedStory = action.payload;
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getStories.fulfilled, (state, { payload }) => {
      state.stories.items = payload.items;
      state.stories.totalPages = payload.totalPages;
      state.stories.totalItems = payload.totalItems;
      state.stories.page = payload.page;
      state.stories.size = payload.size;
    });
    reducersBuilder.addCase(getStories.pending, (state, { payload }) => {
      state.stories.isLoading = true;
    });
  },
});

export const { reset, setShareStory, setSelectedStory } = storySlice.actions;
export default storySlice.reducer;
