import { makeStyles } from '@mui/styles';
import { FC } from 'react';
import { trackEvent } from '../../../utils/analytics/analytics';
import { EventActions } from '../../constants';
import { useTypedSelector } from '../../../store/store';
import { Theme } from '@mui/material';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '25px',
    padding: '20px',
    background: 'linear-gradient(180deg, #273e7a59, #0e121e00)',
    marginTop: '25px',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '-40px',
      marginRight: '-40px',
    },
  },
  imageBlock: {
    // display: 'flex',
    // justifyContent: 'flex-start',
    // alignItems: 'center',
    // height: '100%',
    // minHeight: '123px',
    // width: '128px',
    // minWidth: '128px',
  },
  textBtnBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  textBlock: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
  },
  incentiveImg: {
    borderRadius: '9px',
    objectFit: 'cover',
    height: '128px',
    width: '128px',
  },
  titleText: {
    fontFamily: 'Avenir',
    fontSize: '15px',
    fontWeight: '800',
    margin: 0,
  },
  contentText: {
    fontFamily: 'Avenir',
    color: '#C1BCB5',
    fontSize: '10px',
  },
  delightBtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px',
    fontSize: '16px',
    fontWeight: '600',
    border: '0',
    cursor: 'pointer',
    color: '#fff',
    background: '#F4B93B',
    borderRadius: '8px',
    width: '176px',
  },
  disabled: {
    backgroundColor: '#C1BCB5',
    cursor: 'default',
  },
}));

export const IncentiveAd: FC = () => {
  const styles = useStyles();
  const { venue, venueAttributes } = useTypedSelector((state) => state.venue);
  const settings = venueAttributes.properties['webapp.topgolf.config'];
  const { pathname } = useLocation();
  const isHomeScreen = pathname === '/';
  const handleClick = () => {
    trackEvent({ action: EventActions.incentiveCTA, venueId: venue.id });
    const a = document.createElement('a');
    a.href = settings?.buttonUrl || 'https://topgolf.com/us/plan-a-visit/';
    a.target = '_blank';
    a.click();
  };
  if (!settings?.activeAd) {
    return null;
  }
  return (
    <div className={styles.contentContainer}>
      <div className={styles.imageBlock}>
        <img className={styles.incentiveImg} src={settings?.imageNewUrl || '/img/IncentiveImg.png'} />
      </div>
      <div className={styles.textBtnBlock}>
        <span className={styles.textBlock}>
          <p className={styles.titleText}>{settings?.title || 'Get $10 OFF'}</p>
        </span>
        <span className={styles.contentText}>{settings?.subtitle || 'Share your video to redeem this prize!'}</span>
        <button
          className={[styles.delightBtn, isHomeScreen ? styles.disabled : ''].join(' ')}
          onClick={handleClick}
          disabled={isHomeScreen}
        >
          {settings?.buttonText || 'Delight'}
        </button>
      </div>
    </div>
  );
};
