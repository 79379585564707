import makeStyles from '@mui/styles/makeStyles';

export interface EmailQueryParams {
  svIdent?: string;
  svUn?: string;
  svCat?: string;
  svCl?: string;
  svTl?: string;
  svUid?: string;
}

export const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: '672px',

    '& video': {
      objectFit: 'initial',
    },

    '& img': {
      objectPosition: 'bottom',
    },
  },
  logo: {
    position: 'relative',
    zIndex: 1,
    width: 80,
    height: 80,

    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      marginBottom: 20,
    },

    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      position: 'absolute',
    },

    '& img': {
      objectFit: 'contain',
    },
  },
  button: {
    maxWidth: 672,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  paper: {
    backgroundColor: theme.palette.common.black,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',

    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      overflowY: 'hidden',
    },
  },
  dialogActions: {
    padding: 24,
    width: '100%',
  },
  ctaButton: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  ImageWrapper: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 0,

    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  top: {
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  center: {
    position: 'absolute',
    top: 'calc(50% - 50px)',
    transform: 'translateY(-50%)',
  },
  end: {
    position: 'absolute',
    top: 'calc(100% - 50px)',
    transform: 'translateY(-130%)',
  },
}));

export type PositionType = 'top' | 'center' | 'end';
