export const QuestionIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M6.3335 11H7.66683V9.66671H6.3335V11ZM7.00016 0.333374C3.32016 0.333374 0.333496 3.32004 0.333496 7.00004C0.333496 10.68 3.32016 13.6667 7.00016 13.6667C10.6802 13.6667 13.6668 10.68 13.6668 7.00004C13.6668 3.32004 10.6802 0.333374 7.00016 0.333374ZM7.00016 12.3334C4.06016 12.3334 1.66683 9.94004 1.66683 7.00004C1.66683 4.06004 4.06016 1.66671 7.00016 1.66671C9.94016 1.66671 12.3335 4.06004 12.3335 7.00004C12.3335 9.94004 9.94016 12.3334 7.00016 12.3334ZM7.00016 3.00004C5.52683 3.00004 4.3335 4.19337 4.3335 5.66671H5.66683C5.66683 4.93337 6.26683 4.33337 7.00016 4.33337C7.7335 4.33337 8.3335 4.93337 8.3335 5.66671C8.3335 7.00004 6.3335 6.83337 6.3335 9.00004H7.66683C7.66683 7.50004 9.66683 7.33337 9.66683 5.66671C9.66683 4.19337 8.4735 3.00004 7.00016 3.00004Z"
        fill="#C7C7D9"
      />
    </svg>
  );
};
