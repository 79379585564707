import { defaultVenueSubDomain } from '../api/constants';
import { VenueProperties, VenuePropsDTO, WebappCard } from '../api/models/venue';
import { HeaderProps } from '../common/header';
import { Frame, getCloudinaryFrames } from '../utils';

export const getSubdomain = (route?: string) => {
  if (route) {
    return route.split('.')[1] ? route.split('.')[0] : defaultVenueSubDomain;
  }
  if (typeof window !== 'undefined') {
    return window.location.host.split('.')[1] ? window.location.host.split('.')[0] : defaultVenueSubDomain;
  }
  return '';
};
export const getDonationUrl = (url?: string | null) => {
  if (!url) {
    return '';
  }

  const searchingPart = 'http';
  const httpIndex = url.indexOf(searchingPart);

  if (httpIndex !== 0) {
    return `http://${url}`;
  }

  return url;
};

const defaultSlesHeaderValues: HeaderProps = {
  metaOGTitle: 'A personal message from :name.. check it out!',
  metaOGDescription: '',
  metaOGImg: '',
  metaTwitterSiteId: '@MinutemenCHS',
  metaTwitterTitle: 'A personal message from :name.. check it out!',
  metaTwitterImg: '',
  metaTwitterDescription: '',
  metaOGUrl: '',
};

export const getSalesSocialMetaTags = (venueProperties: Partial<VenueProperties>, userName: string): VenuePropsDTO => {
  return {
    metaOGUrl: '',
    metaOGTitle: userName
      ? defaultSlesHeaderValues.metaOGTitle.replace(':name', userName || '')
      : venueProperties['webapp.sharing']?.['meta-og-title'] || '',
    metaOGDescription: defaultSlesHeaderValues.metaOGDescription,
    metaOGImg: defaultSlesHeaderValues.metaOGImg,
    metaOGVideo: '',
    metaTwitterImg: defaultSlesHeaderValues.metaTwitterImg,
    metaTwitterTitle: userName ? defaultSlesHeaderValues.metaTwitterTitle.replace(':name', userName || '') : '',
    metaTwitterSiteId: venueProperties['webapp.sharing']
      ? venueProperties['webapp.sharing']['meta-twitter-site-id']
      : defaultSlesHeaderValues.metaTwitterSiteId,
    metaTwitterDescription: defaultSlesHeaderValues.metaTwitterDescription,
  };
};

export async function copyToClipboard(text: string): Promise<void> {
  return navigator.clipboard.writeText(text);
}

export const extractFramesFromCloudinary = async (videoUrl: string): Promise<Frame[]> => {
  const video = document.createElement('video');
  const videoBlob = await fetch(videoUrl).then((r) => r.blob());
  const videoObjectUrl = URL.createObjectURL(videoBlob);
  video.src = videoObjectUrl;
  while ((video.duration === Infinity || isNaN(video.duration)) && video.readyState < 2) {
    await new Promise((r) => setTimeout(r, 1000));
    video.currentTime = 10000000 * Math.random();
  }
  // const duration = video.duration;
  // const interval = Number((duration / 5).toFixed(1));
  // const intervals = [0, interval, interval * 2, interval * 3, interval * 4];
  return await getCloudinaryFrames(videoUrl, [0]);
};

export const getBestImage = (
  card = {} as WebappCard,
  breakpoint: { desktop: boolean; tablet: boolean; mobile: boolean },
): string | null => {
  const activeBreakpoint = breakpoint.desktop ? 'desktop' : breakpoint.tablet ? 'tablet' : 'mobile';

  return card[activeBreakpoint]?.cropped || card.desktop?.cropped || card.tablet?.cropped || card.mobile?.cropped;
};