import { FC, useEffect, useRef } from 'react';
import { Swiper as SwiperType, EffectCards } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';

import { useStyles } from './ThumbnailSlider.helper';
import { StoriesApiModel } from '../../../api/models/stories';
import { trackEvent } from '../../../utils/analytics/analytics';
import { EventActions } from '../../constants';
import { useTypedSelector } from '../../../store/store';
import { convertTZ } from '../../../utils/time';

interface StoriesWithDateTime extends StoriesApiModel {
  hours: number;
  minutes: number;
  timeSlot: string;
}

interface ThumbnailSliderProps {
  thumbnails: StoriesWithDateTime[];
  selectedIndex: number;
  changeSelectedIndex: (newSelectedIndex: number) => void;
}

export const ThumbnailSlider: FC<ThumbnailSliderProps> = ({ thumbnails, selectedIndex, changeSelectedIndex }) => {
  const styles = useStyles();
  const swiperInstance = useRef<SwiperType | null>(null);
  const { venue } = useTypedSelector((state) => state.venue);

  useEffect(() => {
    if (swiperInstance.current?.activeIndex !== selectedIndex) {
      swiperInstance.current?.slideTo(selectedIndex);
    }
  }, [selectedIndex]);

  const slidesJsx = thumbnails.map(({ id, thumbnailUrl, shotTime }, index) => {
    let slideClassName = styles.slide;
    let imageClassName = styles.img;

    const distanceFromCenter = Math.abs(index - selectedIndex);

    if (distanceFromCenter === 0) {
      slideClassName = styles.activeSlide;
      imageClassName = styles.imgactiveSlide;
    } else if (distanceFromCenter === 1) {
      // slideClassName = styles.distance1Slide;
      // imageClassName = styles.imgDistance1Slide;
    } else if (distanceFromCenter >= 5) {
      slideClassName = styles.hidden;
      imageClassName = styles.hidden;
    }
    const date = convertTZ(shotTime, 'PST');
    const [hours, minutes] = [date.getHours(), date.getMinutes()];
    const remainder = hours % 12;
    const isAM = hours === 0 ? true : 24 - hours > 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedHours = remainder === 0 ? '12' : remainder;
    const logTime = `${formattedHours}:${formattedMinutes}${isAM ? ' AM' : ' PM'}`;

    return (
      <SwiperSlide key={id}>
        <div className={slideClassName} key={id}>
          <img className={imageClassName} src={thumbnailUrl} alt={shotTime} />
        </div>
        <div className={styles.filler} />
        {index === selectedIndex && (
          <div className={styles.shotTimeContainer}>
            <span className={styles.shotTimeText}>Shot time</span>
            <span className={styles.shotTime}>{logTime}</span>
          </div>
        )}
      </SwiperSlide>
    );
  });
  const handleSlideChange = (index: number) => {
    trackEvent({ action: EventActions.scrollVideo, venueId: venue.id });
    changeSelectedIndex(index);
  };

  return (
    <Swiper
      style={{ overflow: 'hidden' }}
      initialSlide={selectedIndex}
      // longSwipes={false}
      effect="cards"
      grabCursor={true}
      modules={[EffectCards]}
      cardsEffect={{ slideShadows: false }}
      // shortSwipes={!isMobile}
      // followFinger={!isMobile}
      // spaceBetween={80}
      // slidesPerView={5}
      // centeredSlides={true}
      slideToClickedSlide={true}
      onSlideChange={(swiper) => handleSlideChange(swiper.activeIndex)}
      onSwiper={(swiper) => {
        swiperInstance.current = swiper;
      }}
    >
      {slidesJsx}
    </Swiper>
  );
};
