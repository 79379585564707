import { makeStyles } from '@mui/styles';

export const NoContentStyles = makeStyles(() => ({
  main: {
    width: '100%',
    minWidth: '375px',
    padding: '24px',
  },
  title: {
    color: '#fff',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '800',
    opacity: '0.5',
  },

  subtitle: {
    color: '#fff',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '400',
  },

  teeTimesContainer: {
    margin: '15px 0',
  },

  thumbnailsContainer: {
    margin: '40px 0',
    // border: '1px solid white',
    position: 'relative',
  },
  thumbnailsCaptionContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  thumbnailsCaptionTitle: {
    wordBreak: 'break-word',
    marginTop: '12px',
    margin: 0,
    padding: 0,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'center',
  },
  thumbnailsCaptionCaption: {
    wordBreak: 'break-word',
    margin: 0,
    padding: 0,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '19px',
    letterSpacing: '0em',
    textAlign: 'center',
    opacity: 0.7,
  },
}));
