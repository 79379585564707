import { FC } from 'react';

export const InstagramIcon: FC = () => (
  <svg width={29} height={28} viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.476 10.1326C20.0931 10.1326 20.5934 9.63311 20.5934 9.01692C20.5934 8.40074 20.0931 7.90123 19.476 7.90123C18.8588 7.90123 18.3585 8.40074 18.3585 9.01692C18.3585 9.63311 18.8588 10.1326 19.476 10.1326Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4931 9.19292C11.8457 9.19292 9.701 11.3353 9.701 13.9776C9.701 16.6209 11.8457 18.7633 14.4931 18.7633C17.1384 18.7633 19.2852 16.6209 19.2852 13.9776C19.2852 11.3353 17.1384 9.19292 14.4931 9.19292ZM14.4931 17.0866C12.7734 17.0866 11.3792 15.6946 11.3792 13.9786C11.3792 12.2616 12.7734 10.8706 14.4931 10.8706C16.2128 10.8706 17.6049 12.2616 17.6049 13.9786C17.6049 15.6946 16.2128 17.0866 14.4931 17.0866Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.6099 5.15854C21.8631 5.64187 22.8531 6.6313 23.3392 7.88258C23.617 8.61533 23.7621 9.39259 23.7756 10.1771C23.8337 11.1676 23.8337 11.4884 23.8337 14.0168C23.8337 16.5453 23.8202 16.8609 23.7756 17.8566C23.7601 18.6307 23.6139 19.3945 23.3413 20.119C22.8551 21.3703 21.8652 22.3587 20.612 22.8441C19.8864 23.1152 19.1203 23.2622 18.346 23.2777C17.3561 23.3346 17.0316 23.3346 14.5003 23.3346C11.969 23.3346 11.6529 23.3212 10.6546 23.2777C9.87719 23.2529 9.11117 23.0935 8.38971 22.812C7.13545 22.3276 6.14552 21.3382 5.65937 20.0879C5.38675 19.3624 5.24059 18.5986 5.22504 17.8245C5.16699 16.834 5.16699 16.5121 5.16699 13.9847C5.16699 11.4563 5.18047 11.1407 5.22504 10.145C5.24059 9.37086 5.38675 8.60705 5.65937 7.88258C6.14448 6.6313 7.13441 5.64187 8.38867 5.15854C9.11324 4.88531 9.87927 4.73938 10.6526 4.72386C11.6425 4.66797 11.9669 4.66797 14.4983 4.66797C17.0296 4.66797 17.3457 4.68039 18.344 4.72386C19.1193 4.74042 19.8853 4.88635 20.6099 5.15854ZM21.7543 19.4949C21.9616 18.935 22.0694 18.344 22.0767 17.7479V17.7489H22.0881C22.1326 16.7595 22.1326 16.4883 22.1326 13.9671C22.1326 11.4449 22.1212 11.1686 22.0767 10.1843C22.0673 9.59441 21.9575 9.00966 21.7543 8.45698C21.4381 7.64246 20.7944 6.99871 19.9797 6.68512C19.4251 6.47916 18.8405 6.37049 18.2486 6.36325C17.2462 6.30632 16.9871 6.30632 14.4609 6.30632C11.9348 6.30632 11.658 6.31874 10.6722 6.36325C10.0824 6.37152 9.49574 6.4802 8.9422 6.68512C8.12434 6.99768 7.47648 7.64039 7.16033 8.45698C6.95301 9.0169 6.84521 9.60683 6.83795 10.203C6.78301 11.2058 6.78301 11.4636 6.78301 13.9857C6.78301 16.5069 6.79338 16.7843 6.83795 17.7675C6.84728 18.3575 6.95716 18.9422 7.16033 19.4949C7.47648 20.3105 8.12434 20.9542 8.9422 21.2657C9.49574 21.4717 10.0824 21.5804 10.6722 21.5876C11.6757 21.6445 11.9338 21.6445 14.4609 21.6445C16.986 21.6445 17.2638 21.6332 18.2486 21.5876C18.8405 21.5793 19.4251 21.4717 19.9797 21.2657C20.7944 20.9532 21.4381 20.3094 21.7543 19.4949Z"
      fill="currentColor"
    />
  </svg>
);
