import { FC } from 'react';
import Image from 'next/image';
import { makeStyles } from '@mui/styles';

const iconPath = '/SV Grey.png';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '4px',
    position: 'absolute',
    top: 'calc(100% - 80px)',
  },
  text: {
    marginLeft: '12px',
    fontSize: '12px',
    fontWeight: 300,
  },
}));

export const BottomBar: FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Image src={iconPath} height={24} width={24} alt="" />
      <p className={classes.text}>{'Powered By SocialVenu'}</p>
    </div>
  );
};
