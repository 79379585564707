import { FC } from 'react';

export const FacebookIcon: FC = () => (
  <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.09291 1.97029C7.06448 1.97029 5.42008 3.62233 5.42008 5.66022V8.29925C5.42008 8.65888 5.12989 8.95041 4.77193 8.95041H1.96329V11.0866H4.47133C4.8293 11.0866 5.11948 11.3781 5.11948 11.7377V18.0323H7.43029V11.7377C7.43029 11.3781 7.72047 11.0866 8.07843 11.0866H10.283L10.8466 8.91603H8.22872C7.87075 8.91603 7.58057 8.6245 7.58057 8.26487V6.52843C7.58057 5.20981 8.64455 4.14084 9.95711 4.14084H11.0374V1.97029H9.09291ZM4.12378 5.66022C4.12378 2.90308 6.34856 0.667969 9.09291 0.667969H11.6855C12.0435 0.667969 12.3337 0.959504 12.3337 1.31913V4.792C12.3337 5.15163 12.0435 5.44316 11.6855 5.44316H9.95711C9.36049 5.44316 8.87687 5.92905 8.87687 6.52843V7.61371H11.6855C11.8863 7.61371 12.0757 7.70716 12.1984 7.86676C12.3211 8.02636 12.3633 8.23409 12.3127 8.42924L11.4109 11.9021C11.3364 12.1888 11.0787 12.3889 10.7837 12.3889H8.72658V18.6835C8.72658 19.0431 8.4364 19.3346 8.07843 19.3346H4.47133C4.11337 19.3346 3.82319 19.0431 3.82319 18.6835V12.3889H1.31514C0.957178 12.3889 0.666992 12.0974 0.666992 11.7377V8.29925C0.666992 7.93962 0.957178 7.64809 1.31514 7.64809H4.12378V5.66022Z"
      fill="white"
    />
  </svg>
);
