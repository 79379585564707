import { VenueAPIModel, VenueProperties } from '../api/models/venue';
import { StoryDetails } from '../api/models/auth';
import { StoriesApiModel, StoriesWithDateTime } from '../api/models/stories';
import { Frame } from '../utils';

export interface VenueDTO extends VenueAPIModel {
  isLoading: boolean;
  error: boolean;
  lastUpdated: string;
}

export interface VenueAttributesDTO {
  isLoading: boolean;
  error: boolean;
  lastUpdated: string;
  properties: Partial<VenueProperties>;
}

export interface StoriesDTO {
  stories: PaginationModel<StoriesApiModel>;
  currentStory: StoriesApiModel | null;
  shareStory: StoriesApiModel | null;
  shareStoryLoading: boolean;
  returnUrl: string | null;
  newStoriesCount: number;
  mainCategory: null | CategoryApiModel;
  filterPublicId: string;
  selectedTags: string[] | null;
  selectedStory: null | StoriesWithDateTime;
}

export interface StoriesToday {
  details?: StoryDetails;
  featured: false;
  id: string;
  shortcode: string;
  shotTime: string;
  status: string;
  teeTime: string;
  thumbnailUrl: string;
  url: string;
  venueId: string;
}

export interface PaginationModel<T> {
  items: T[];
  isLoading: boolean;
  totalPages: number;
  totalItems: number;
  page: number;
  size: number;
}

export interface TagsApiModel {
  id: string;
  name: string;
}

export interface CategoryApiModel {
  id: string;
  venueId: string;
  name: string;
  code: string;
  imageUrl: string;
  filterId: string;
  filterName: string;
  filterUrl: string;
  returnUrl: string;
  filterEnabled: boolean;
}

export enum UploadProcessStep {
  AddCellPhone,
  AddHandle,
  AddPassword,
  AddEmail,
  UploadVideo,
  PrepareVideo,
  ConversionDone,
  VideoUploading,
  Uploaded,
}

export interface UploadProcessDTO {
  currentStep: UploadProcessStep;
  cellPhone: string;
  handle: string;
  password: string;
  email: string;
  uploadingProgress: number;
  supportedMimeType: string;
  fileSelected: boolean;
  thumbnailFrameurl: Frame | null;
}
